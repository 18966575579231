import React from "react";
import tw from "twin.macro";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Row,
  Col,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";
import "../styles/testimonial.css";
import video_1 from "../images/Testimonials/Video-image1.jpeg";
// import video_2 from "../images/Testimonials/Video-image2.jpeg";
import Google from "../images/Testimonials/google.png";
import Facebook from "../images/Testimonials/facebook.png";
import Star from "../images/Testimonials/star.png";

const Container = tw.div`relative`;

export default ({
  sliderItem = [
    {
      imagesrc: video_1,
      video: 'https://www.youtube.com/embed/LoSZyuPpfSk?rel=0',
      rightContent: `Zinger has grown with trending technology and they have taken the step ahead as the best One Stop Jewellery ERP software development companies. As one of the fastest growing ERP development company, we continually strengthen, develop and update our software with better innovative solutions and features to ensure the effectiveness of the software and help smoothening of business.`,
    },
    {
      imagesrc: video_1,
      video: 'https://www.youtube.com/embed/ig9F0KPPXtc?rel=0',
      rightContent: `Zinger brings customers a convenient way to shop your favourite jewels. View collection live, compare and shortlist, raise your queries and make your choice ... all through a live video session at a convenient time & location with your near & dear once. Enjoy Zinger Live. Have a safe shopping.`,
    },
  ],
  rightCardItem = [
    {
      Imagesrc: Facebook,
      cardTitle: "Ashok Kumar",
      // cardSubTitle: "16 July",
      cardText:
        "It is very user-friendly to us. More customer support executives to give their relentless services. So I strongly recommend to anyone who want a complete software solutions.",
    },
    {
      Imagesrc: Facebook,
      cardTitle: "Manoj Arumugam",
      // cardSubTitle: "15 July",
      cardText:
        "We are well satisfied with Zinger ERP. It has been couple of years since we have started using this software , feature are extensive. Team keeps innovating new features that helps to enhance the business pertaining to jewelry business. Customer support is their USP and their team does an exemplary work. We are completely satisfied with Zinger and would recommend their service.",
    },
  ],
  leftCardItem = [
    {
      Imagesrc: Google,
      Star: Star,
      cardTitle: "Eswar Jewellers PG Palayam ",
      // cardSubTitle: " 3 months ago",
      cardText:
        "It is very user-friendly to us. More customer support executives to give their relentless services. So I strongly recommend to anyone who want a complete software solutions.",
    },
    {
      Imagesrc: Google,
      Star: Star,
      cardTitle: "Govindaraj Raj ",
      // cardSubTitle: " a year ago",
      cardText:
        "Zinger product is really great with easy options to use as an owner and for employees. Rasi team really helped in migrating from our old software to Zinger in 24 hours time without any outage in our business. The best thing our employee like in this product is user friendly in billing and reporting. And Rasi team is always approachable whenever we needed their help.",
    },
    {
      Imagesrc: Google,
      Star: Star,
      cardTitle: "kishore kattariya S ",
      // cardSubTitle: " 3 months ago",
      cardText:
        "Zinger team is very dedicative in all means.The migration ofJewellery software was very good ,and they had sent team to our showroom and did a tremendous job.The team is very responsive in terms of customisation and Happy to install their software for our showroom.",
    },
    {
      Imagesrc: Google,
      Star: Star,
      cardTitle: "SKJ Jewellers ",
      // cardSubTitle: "N/A",
      cardText:
        "Our shop name is S Kandasamy & Sons from Karumathampatti. We are using Zinger software. It has all the requirement and things we need in jewellery software. The Zinger team is available anytime to provide support. Even when the store is crowded, they help us when we call. We have seen good results within two months of using this Zinger software.All customers visiting our first branch said their billing was clear and better than other stores.  With  Zinger  team support we have started our second jewellery shop ( 2nd Branch ). Zinger team development and customer support is best support.",
    },
    {
      Imagesrc: Google,
      Star: Star,
      cardTitle: "Karthik Thangamaligai",
      // cardSubTitle: "N/A",
      cardText: "My name is Sai Balaji. We have opened a new jewellery store called Karthik Thangamaaligai at Vadavalli in Coimbatore. We researched a lot of software when we opened. Who is better? Then I got a recommendation for Zinger software. First, they gave us a demo. We were satisfied with it. We bought the software from them and implemented it. It was very user-friendly. Their staff was also friendly. They understand what we say and fulfill the requirements accordingly. No matter how often you call them, they do what you want & they will clarify your doubts without getting bored. It is also handy for business. and from estimation to billing, it can be done quickly. Zinger software does not require a lot of paperwork and manpower. Also, it is not necessary that only experienced workers are needed. Thus the Zinger software team has proved that freshers can work in jewellery stores just like freshers can work in malls.I am so happy to use this Zinger software.",
    }
  ],
}) => {

  return (
    <div>
      <Header isActiveTab="4" />
      <Container className="testimonial-container-bg">
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={7000}
          transitionTime={500}
          className="testimonial_bg"
        >
          {sliderItem.map((content, idx) => (
            <div key={idx}>
              <Row>
                <Col md="7" sm="7" xs="12" className="my-3">
                <Row>
                  <Col md="1" sm="1" xs="1"></Col>
                    <Col md="10" sm="10" xs="10">
                  <div className="testimonial-video">
                    <iframe
                      width="100%"
                      height="250"
                      src={content.video}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  </Col>
                  </Row>
                </Col>
                <Col md="5" sm="5" xs="12" className="my-3">
                  <Row>
                    <Col md="11" sm="11" xs="11" className="my-4">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: content.rightContent,
                        }}
                      ></p>
                    </Col>
                    <Col md="1" sm="1" xs="1"></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
        <Row>
          <Col md="6" sm="12" xs="12" className="my-3">
            <Row>
              <Col md="12" sm="12" xs="12" className="testimonial-animation">
                <h3 className="text-center">Google Reviews</h3>
                <div className="testimonial-cus">
                  {leftCardItem.map((items, index) => (
                    <Row key={index}>
                      <Col md="2" sm="2" xs="2" className="my-4">
                        <img
                          src={items.Imagesrc}
                          alt={items.cardTitle}
                          width="80%"
                          className="testimonial-cus-photo"
                        />
                      </Col>
                      <Col md="10" sm="10" xs="10">
                        <CardBody>
                          <CardTitle tag="p">
                            {items.cardTitle}
                            <br />
                            <span>
                              <img
                                src={items.Star}
                                alt="Star"
                                width="4%"
                                style={{ display: "inline-flex" }}
                              />
                              <img
                                src={items.Star}
                                alt="Star"
                                width="4%"
                                style={{ display: "inline-flex" }}
                              />
                              <img
                                src={items.Star}
                                alt="Star"
                                width="4%"
                                style={{ display: "inline-flex" }}
                              />
                              <img
                                src={items.Star}
                                alt="Star"
                                width="4%"
                                style={{ display: "inline-flex" }}
                              />
                              <img
                                src={items.Star}
                                alt="Star"
                                width="4%"
                                style={{ display: "inline-flex" }}
                              />
                            </span>
                            <small>{items.cardSubTitle}</small>
                          </CardTitle>
                          <CardText className="testimonial-card-text">
                            {items.cardText}
                          </CardText>
                        </CardBody>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>

          <Col md="6" sm="12" xs="12" className="my-4">
            <Row>
              {/* <Col md="1" sm="1" xs="1"></Col> */}
              <Col
                md="12"
                sm="12"
                xs="12"
                className="testimonial-animation-facebook"
              >
                <h3 className="text-center">Facebook Reviews</h3>

                {rightCardItem.map((item, index) => (
                  <>
                    <Row key={index}>
                      <Col md="2" sm="2" xs="2" className="my-4">
                        <img
                          src={item.Imagesrc}
                          alt={item.cardTitle}
                          width="80%"
                          className="testimonial-cus-photo"
                        />
                      </Col>
                      <Col md="10" sm="10" xs="10">
                        <CardBody>
                          <CardTitle tag="p">
                            {item.cardTitle} <small>{item.cardSubTitle}</small>
                          </CardTitle>
                          <CardText className="testimonial-card-text">
                            {item.cardText}
                          </CardText>
                        </CardBody>
                      </Col>
                    </Row>
                  </>
                ))}
              </Col>
              {/* <Col md="1" sm="1" xs="1"></Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
