import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  Nav,
  NavItem,
  Col,
  Row,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import emailjs from "emailjs-com";
import Captcha from "react-numeric-captcha";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import { countryCodeLists } from "./CountryCodeListJson.js";
import { ReactComponent as CheckboxIcon } from "../images/Plans/checkbox-circle.svg";
import "../styles/pricing.css";

const Container = tw.div`relative`;

const PlansContainer = tw.div`my-5 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;

const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm px-2 sm:px-2 lg:px-6 lg:py-2 xl:p-5 mx-2 flex flex-col justify-between mt-5 first:mt-0 lg:mt-0 shadow-raised`}
`;

export default ({
  StarterLeft = [
    { content: "Estimation" },
    { content: "Billing" },
    { content: "Accounting" },
  ],
  StarterRight = [
    { content: "User Entitlement" },
    { content: "Report & Analysis" },
    { content: "Basic Reports" },
  ],
  StarterPlusLeft = [
    { content: "Estimation" },
    { content: "Billing" },
    { content: "Accounting" },
    { content: "Chit/Saving Scheme Module" },
  ],
  StarterPlusRight = [
    { content: "User Entitlement" },
    { content: "Report & Analysis" },
    { content: "Basic Reports" },
    { content: "Order Management" },
    { content: "Karigar Module" },
  ],
  RegularLeft = [
    { content: "Estimation" },
    { content: "Billing" },
    { content: "Accounting" },
    { content: "Chit/Saving Scheme Module" },
    { content: "Karigar Module" },
    { content: "Advanced Reporting" },
    { content: "Alert & Notification" },
    { content: "Basic CRM" },
  ],
  RegularRight = [
    { content: "User Entitlement" },
    { content: "Report & Analysis" },
    { content: "Basic Reports" },
    { content: "Order Management" },
    { content: "Chit Mobile App (Android)" },
    { content: "Payment Gateway Integration" },
    { content: "Feedback Management" },
    { content: "Payroll Management" },
  ],
  RegularPlusLeft = [
    { content: "Estimation" },
    { content: "Billing" },
    { content: "Accounting" },
    { content: "Chit/Saving Scheme Module" },
    { content: "Karigar Module" },
    { content: "Advanced Reporting" },
    { content: "Alert & Notification" },
    { content: "Basic CRM" },
    { content: "Payroll Management" },
    { content: "Scheduled Reports" },
    { content: "Loyalty Management Programme" },
  ],
  RegularPlusRight = [
    { content: "User Entitlement" },
    { content: "Report & Analysis" },
    { content: "Basic Reports" },
    { content: "Order Management" },
    { content: "Chit Mobile App (Android)" },
    { content: "Payment Gateway Integration" },
    { content: "Feedback Management" },
    { content: "Portfolio Website Development" },
    { content: "3rd Party Integration" },
    { content: "Owner Insight Moblie App (Android)" },
  ],
  EnterprisesLeft = [
    { content: "Estimation" },
    { content: "Billing" },
    { content: "Accounting" },
    { content: "Chit/Saving Scheme Module" },
    { content: "Karigar Module" },
    { content: "Advanced Reporting" },
    { content: "Alert & Notification" },
    { content: "Basic CRM" },
    { content: "Payroll Management" },
    { content: "Scheduled Reports" },
    { content: "Loyalty Management Programme" },
    { content: "Owner Insight Moblie App (Android)" },
    { content: "Sales Mobile App (Android)" },
  ],
  EnterprisesRight = [
    { content: "User Entitlement" },
    { content: "Report & Analysis" },
    { content: "Basic Reports" },
    { content: "Order Management" },
    { content: "Chit Mobile App (Android)" },
    { content: "Payment Gateway Integration" },
    { content: "Feedback Management" },
    { content: "Portfolio Website Development" },
    { content: "3rd Party Integration" },
    { content: "HO Module" },
    { content: "Advanced CRM" },
    { content: "Enquiry & Follow-Up Management" },
    { content: "Field Sales Management" },
    { content: "Employee Location Tracking Module" },
    { content: "Hardware Integration" },
  ],
}) => {
  const [activeTab, setActiveTab] = useState("Starter");
  const [isModal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [remarks, setRemarks] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const [plans, setPlans] = useState("Customized");
  const [invalidFlag, setInvalidFlag] = useState({
    name: false,
    phone: false,
    remarks: false,
  });

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = () => {
    const countryCodeListData = [];
    countryCodeListData.push({ value: "", label: "Select" });
    countryCodeLists.forEach((item) => {
      countryCodeListData.push({
        dial_code: item.dial_code,
        digits: item.digits ? item.digits : 15,
        value: item.dial_code,
        label: `${item.code}(${item.dial_code}) `,
        flag: item.flag,
        code: item.code,
      });
    });
    setCountryCodeList(countryCodeListData);
  };

  const isActiveTab = (isActiveTab) => {
    setActiveTab(isActiveTab);
  };

  const openModal = (planDetail) => {
    setPlans(planDetail);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setName("");
    setCountryCode("+91");
    setPhone("");
    setRemarks("");
    setCaptchaSuccess(false);
    setNotificationModal(false);
    setPlans("Customized");
    setInvalidFlag({
      name: false,
      phone: false,
      remarks: false,
    });
  };

  const submitForm = () => {
    postAjaxRequest();
  };

  const toggleNotificationModal = () => {
    setNotificationModal(false);
  };

  const postAjaxRequest = () => {
    if (validateFields() && captchaSuccess) {
      let phoneNo = "";
      if (selectedCountryCode) {
        phoneNo += `${selectedCountryCode}${phone}`;
      } else {
        phoneNo = phone;
      }
      const templateParams = {
        name: name,
        phone: phoneNo,
        remarks: `${plans} Plan, ${remarks}`,
        email: "",
      };
      emailjs
        .send(
          "service_m6obie7",
          "template_aeildhh",
          templateParams,
          "user_6n2BpAAMVe0g19wywmeBb"
        )
        .then(
          (data) => {
            setNotificationModal(true);
            setName("");
            setCountryCode("+91");
            setPhone("");
            setRemarks("");
            setCaptchaSuccess(false);
            setModal(false);
            setInvalidFlag({
              name: false,
              phone: false,
              remarks: false,
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const validateField = (
    fieldName: string,
    fieldValue: any,
    isRequired: boolean
  ) => {
    let isValid = false;
    if (isRequired === true) {
      isValid =
        fieldValue.toString().trim().length === 0
          ? false
          : fieldName.test(fieldValue.toString());
    } else {
      isValid =
        fieldValue.toString().trim().length > 0
          ? fieldName.test(fieldValue.toString())
          : true;
    }
    return isValid;
  };

  const validateFields = () => {
    let isValid = true;

    if (!validateField(/^[a-zA-Z0-9. ]+$/i, name, true)) {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: true }));
      isValid = false;
    } else {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: false }));
    }

    if (selectedCountryCode) {
      const telCodeFind = countryCodeList.filter(function (obj) {
        return obj.dial_code === selectedCountryCode;
      });
      const countryCodeDigits =
        telCodeFind.length > 0 ? telCodeFind[0].digits : 15;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        (countryCodeDigits !== phone.length && telCodeFind.length > 0)
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
        isValid = false;
      } else {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: false }));
      }
    } else {
      const countryCodeDigits = 10;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        countryCodeDigits !== phone.length
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
      }
    }

    return isValid;
  };

  return (
    <div>
      <Header isActiveTab="3" />
      <Container className="plans_bg">
        <Nav className="justify-content-center py-4">
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Starter" ? "plansBtn plansBtnActive" : "plansBtn"
              }
              onClick={() => isActiveTab("Starter")}
            >
              Starter
            </div>
          </NavItem>
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Starter Plus"
                  ? "plansBtn plansBtnActive"
                  : "plansBtn"
              }
              onClick={() => isActiveTab("Starter Plus")}
            >
              Starter Plus
            </div>
          </NavItem>
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Regular" ? "plansBtn plansBtnActive" : "plansBtn"
              }
              onClick={() => isActiveTab("Regular")}
            >
              Regular
            </div>
          </NavItem>
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Regular Plus"
                  ? "plansBtn plansBtnActive"
                  : "plansBtn"
              }
              onClick={() => isActiveTab("Regular Plus")}
            >
              Regular Plus
            </div>
          </NavItem>
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Enterprise"
                  ? "plansBtn plansBtnActive"
                  : "plansBtn"
              }
              onClick={() => isActiveTab("Enterprise")}
            >
              Enterprise
            </div>
          </NavItem>
          <NavItem className="py-2">
            <div
              className={
                activeTab === "Customized"
                  ? "plansBtn plansBtnActive"
                  : "plansBtn"
              }
              onClick={() => openModal("Customized")}
            >
              Customized
            </div>
          </NavItem>
        </Nav>
        {(() => {
          if (activeTab === "Starter")
            return (
              <Row>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div">
                      {StarterLeft.map((item, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {item.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col md="4" sm="12" className="hidden-xs">
                  <PlansContainer>
                    <Plan>
                      <h2 className="text-center">Starter</h2>
                      <Card className="pricingCard">
                        <div className="pricingCardup">
                          <h5>PERPETUAL</h5>
                          <p>(Onetime + AMC)</p>
                        </div>
                        <div className="pricingCardborder"></div>
                        <div className="pricingCarddown">
                          <h5>SAAS</h5>
                          <p>(Cloud)</p>
                        </div>
                      </Card>
                      <div className="PricingContent mt-2">
                        <h5>Billing</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Accounting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>User Entitlement</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Report & Analysis</h5>
                      </div>
                      <br />
                      <Button onClick={() => openModal("Starter")} color="primary">Sent Enquiry</Button>
                    </Plan>
                  </PlansContainer>
                </Col>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div_right">
                      {StarterRight.map((items, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {items.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          if (activeTab === "Starter Plus")
            return (
              <Row>
                <Col md="4" className="pricing_col">
                  {/* <AnimationPricingPage> */}
                  <div className="pricing_div">
                    {StarterPlusLeft.map((item, index) => (
                      <p key={index} className="pricing_paragraph">
                        <span>
                          <CheckboxIcon className="pricing_icon" />
                        </span>
                        {item.content}
                      </p>
                    ))}
                  </div>
                  {/* </AnimationPricingPage> */}
                </Col>
                <Col md="4" className="hidden-xs">
                  <PlansContainer>
                    <Plan>
                      <h2 className="text-center">Starter Plus</h2>
                      <Card className="pricingCard">
                        <div className="pricingCardup">
                          <h5>PERPETUAL</h5>
                          <p>(Onetime + AMC)</p>
                        </div>
                        <div className="pricingCardborder"></div>
                        <div className="pricingCarddown">
                          <h5>SAAS</h5>
                          <p>(Cloud)</p>
                        </div>
                      </Card>
                      <div className="PricingContent mt-2">
                        <h5>Billing</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Accounting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit/Saving Scheme Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>User Entitlement</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Order Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Karigar Module</h5>
                      </div>
                      <br />
                      <Button color="primary" onClick={() => openModal("Starter Plus")}>Sent Enquiry</Button>
                    </Plan>
                  </PlansContainer>
                </Col>
                <Col md="4" className="pricing_col">
                  {/* <AnimationPricingPage> */}
                  <div className="pricing_div_right">
                    {StarterPlusRight.map((items, index) => (
                      <p key={index} className="pricing_paragraph">
                        <span>
                          <CheckboxIcon className="pricing_icon" />
                        </span>
                        {items.content}
                      </p>
                    ))}
                  </div>
                  {/* </AnimationPricingPage> */}
                </Col>
              </Row>
            );
          if (activeTab === "Regular")
            return (
              <Row>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div">
                      {RegularLeft.map((item, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {item.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="hidden-xs">
                  <PlansContainer>
                    <Plan>
                      <h2 className="text-center">Regular</h2>
                      <Card className="pricingCard">
                        <div className="pricingCardup">
                          <h5>PERPETUAL</h5>
                          <p>(Onetime + AMC)</p>
                        </div>
                        <div className="pricingCardborder"></div>
                        <div className="pricingCarddown">
                          <h5>SAAS</h5>
                          <p>(Cloud)</p>
                        </div>
                      </Card>
                      <div className="PricingContent mt-2">
                        <h5>Billing</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Accounting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit/Saving Scheme Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>User Entitlement</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Order Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Karigar Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Advanced Reporting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit Mobile App (Android)</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payment Gateway Integration</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Feedback Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payroll Management</h5>
                      </div>
                      <br />
                      <Button color="primary" onClick={() => openModal("Regular")}>Sent Enquiry</Button>
                    </Plan>
                  </PlansContainer>
                </Col>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div_right">
                      {RegularRight.map((items, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {items.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          if (activeTab === "Regular Plus")
            return (
              <Row>
                <Col md="4" className="pricing_col">
                  {/* <AnimationPricingPage> */}
                  <div className="pricing_div">
                    {RegularPlusLeft.map((item, index) => (
                      <p key={index} className="pricing_paragraph">
                        <span>
                          <CheckboxIcon className="pricing_icon" />
                        </span>
                        {item.content}
                      </p>
                    ))}
                  </div>
                  {/* </AnimationPricingPage> */}
                </Col>
                <Col md="4" className="hidden-xs">
                  <PlansContainer>
                    <Plan>
                      <h2 className="text-center">Regular Plus</h2>
                      <Card className="pricingCard">
                        <div className="pricingCardup">
                          <h5>PERPETUAL</h5>
                          <p>(Onetime + AMC)</p>
                        </div>
                        <div className="pricingCardborder"></div>
                        <div className="pricingCarddown">
                          <h5>SAAS</h5>
                          <p>(Cloud)</p>
                        </div>
                      </Card>
                      <div className="PricingContent mt-2">
                        <h5>Billing</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Accounting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit/Saving Scheme Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>User Entitlement</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Order Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Karigar Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Advanced Reporting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit Mobile App (Android)</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payment Gateway Integration</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Feedback Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payroll Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Loyalty Management Programme</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Portfolio Website Development</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>3rd Party Integration</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Owner Insight Moblie App (Android)</h5>
                      </div>
                      <br />
                      <Button color="primary" onClick={() => openModal("Regular Plus")}>Sent Enquiry</Button>
                    </Plan>
                  </PlansContainer>
                </Col>
                <Col md="4" className="pricing_col">
                  {/* <AnimationPricingPage> */}
                  <div className="pricing_div_right">
                    {RegularPlusRight.map((items, index) => (
                      <p key={index} className="pricing_paragraph">
                        <span>
                          <CheckboxIcon className="pricing_icon" />
                        </span>
                        {items.content}
                      </p>
                    ))}
                  </div>
                  {/* </AnimationPricingPage> */}
                </Col>
              </Row>
            );
          if (activeTab === "Enterprise")
            return (
              <Row>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div">
                      {EnterprisesLeft.map((item, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {item.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="hidden-xs">
                  <PlansContainer>
                    <Plan>
                      <h2 className="text-center">Enterprises</h2>
                      <Card className="pricingCard">
                        <div className="pricingCardup">
                          <h5>PERPETUAL</h5>
                          <p>(Onetime + AMC)</p>
                        </div>
                        <div className="pricingCardborder"></div>
                        <div className="pricingCarddown">
                          <h5>SAAS</h5>
                          <p>(Cloud)</p>
                        </div>
                      </Card>
                      <div className="PricingContent mt-2">
                        <h5>Billing</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Accounting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit/Saving Scheme Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>User Entitlement</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Order Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Karigar Module</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Advanced Reporting</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Chit Mobile App (Android)</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payment Gateway Integration</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Feedback Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Payroll Management</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Loyalty Management Programme</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Portfolio Website Development</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>3rd Party Integration</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Owner Insight Moblie App (Android)</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Sales Mobile App (Android)</h5>
                      </div>
                      <div className="PricingContent mt-2">
                        <h5>Employee Location Tracking Module</h5>
                      </div>
                      <br />
                      <Button color="primary" onClick={() => openModal("Enterprise")}>Sent Enquiry</Button>
                    </Plan>
                  </PlansContainer>
                </Col>
                <Col md="4" className="pricing_col">
                  <div>
                    <div className="pricing_div_right">
                      {EnterprisesRight.map((items, index) => (
                        <p key={index} className="pricing_paragraph">
                          <span>
                            <CheckboxIcon className="pricing_icon" />
                          </span>
                          {items.content}
                        </p>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            );
        })()}
      </Container>
      <Footer />
      {/* customized modal start */}
      <Modal isOpen={isModal}>
        <ModalHeader className="p-2 text-center" toggle={() => closeModal()}>
          Send Enquiry
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-1">
              <Label for="name">Name :</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={name}
                invalid={invalidFlag.name}
                placeholder="Name"
                bsSize="sm"
                onChange={(e) => {
                  setName(e.target.value);
                  setInvalidFlag((invalidFlag) => ({
                    ...invalidFlag,
                    name: false,
                  }));
                }}
              />
            </FormGroup>
            <FormGroup className="mb-1">
              <Label for="phone">Mobile Number :</Label>
              <InputGroup Row>
                <Col md="3" sm="3" xs="3">
                  <Input
                    type="select"
                    id="selectedCountryCode"
                    bsSize="sm"
                    value={selectedCountryCode}
                    className="col-md-3"
                    onChange={(e) => setCountryCode(e.target.value)}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    {countryCodeList.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Input>
                </Col>
                <Col md="9" sm="9" xs="9">
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    invalid={invalidFlag.phone}
                    placeholder="Phone"
                    bsSize="sm"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setInvalidFlag((invalidFlag) => ({
                        ...invalidFlag,
                        phone: false,
                      }));
                    }}
                    style={{ borderTopLeftRadius: 0, borderLeftRadius: 0 }}
                  />
                </Col>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="Text">Requirement Details : <b>({plans} Plan)</b></Label>
              <Input
                type="textarea"
                name="remarks"
                id="remarks"
                value={remarks}
                invalid={invalidFlag.remarks}
                placeholder="Requirement Details"
                bsSize="sm"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
            <Row className="rnc-center">
              <Col md="12">
                <Captcha onChange={(status) => setCaptchaSuccess(status)} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="text-center">
          <Button
            size="sm"
            color="info"
            className="btn w-50 m-auto"
            onClick={submitForm}
          >
            Send
          </Button>
        </ModalFooter>
      </Modal>
      {/* customized modal end */}
      {/* Notification modal start */}
      <Modal isOpen={isNotificationModal}>
        <ModalHeader className="p-2 text-center modal-success">
          Send Mail
        </ModalHeader>
        <ModalBody className="py-3 text-center">
          <div>
            <i
              className="fa fa-thumbs-up"
              color="success"
              style={{ fontSize: "50px", color: "#4dbd74" }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <h4>Thank you!</h4>
          </div>
          <div>
            Your message has been successfully sent. We will contact you very
            soon!
          </div>
        </ModalBody>
        <ModalFooter className="p-0">
          <Col md="4">
            <Button
              block
              size="sm"
              color="info"
              className="btn"
              onClick={toggleNotificationModal}
            >
              OK
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
      {/* Notification modal end */}
    </div>
  );
};
