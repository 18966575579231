import React from "react";
import { useParams } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "pages/Features.js";
import Plans from "pages/Plans.js";
import Testimonials from "pages/Testimonials.js";
import Contact from "pages/Contact.js";
import Demo from "pages/Demo.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import TermsOfServicePage from "pages/TermsOfService.js";

export const components = {
  innerPages: {
    Features: {
      component: Features,
      url: `/components/innerPages/Features`,
    },
    Plans: {
      component: Plans,
      url: `/components/innerPages/Plans`,
    },
    Testimonials: {
      component: Testimonials,
      url: `/components/innerPages/Testimonials`,
    },
    Contact: {
      component: Contact,
      url: `/components/innerPages/Contact`,
    },
    Demo: {
      component: Demo,
      url: `/components/innerPages/Demo`,
    },
    TermsOfServicePage: {
      component: TermsOfServicePage,
      url: `/components/innerPages/TermsOfServicePage`,
    },
    PrivacyPolicyPage: {
      component: PrivacyPolicyPage,
      url: `/components/innerPages/PrivacyPolicyPage`,
    },
  },
};

export default () => {
  const { type, subtype, name } = useParams();

  try {
    let Component = null;
    if (type === "blocks" && subtype) {
      Component = components[type][subtype]["elements"][name].component;
      return (
        <AnimationRevealPage disabled>
          <Component />
        </AnimationRevealPage>
      );
    } else Component = components[type][name].component;

    if (Component) return <Component />;

    throw new Error("Component Not Found");
  } catch (e) {
    console.log(e);
    return <div>Error: Component Not Found</div>;
  }
};
