import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import Slider from "react-slick";
import emailjs from "emailjs-com";
import "slick-carousel/slick/slick.css";
import Captcha from "react-numeric-captcha";
import { reactLocalStorage } from "reactjs-localstorage";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import Header from "./components/headers/header.js";
import Footer from "components/footers/footer.js";
import { countryCodeLists } from "./pages/CountryCodeListJson.js";
import DesignIllustration from "./images/MainLandingPage/desingingPages.jpeg";
import { ReactComponent as SvgDecoratorBlob7 } from "./images/svg/svg-decorator-7.svg";
import { ReactComponent as SvgDecoratorBlob8 } from "./images/svg/svg-decorator-8.svg";
import principles_long_term from "./images/MainLandingPage/principles_long_term1.png";
import principles_focus from "./images/MainLandingPage/principles_focus.png";
import principles_customer_first from "./images/MainLandingPage/principles_customer_first.png";
import migration_friendly from "./images/MainLandingPage/migration_friendly.png";
import Dedicated from "./images/MainLandingPage/Dedicated.png";
import shortest_go_live from "./images/MainLandingPage/shortest_go_live.png";
import Ready_to_use from "./images/MainLandingPage/Ready_to_use.png";
import Infra_Ready from "./images/MainLandingPage/Infra_Ready.png";
import Map from "./images/MainLandingPage/Map.png";
import Flag_of_India from "./images/MainLandingPage/Flag_of_India.png";
import Flag_of_Bahrain from "./images/MainLandingPage/Flag_of_Bahrain.png";
import Flag_of_Qatar from "./images/MainLandingPage/Flag_of_Qatar.png";
import United_Arab_Emirates from "./images/MainLandingPage/United_Arab_Emirates.png";
import Flag_of_USA from './images/MainLandingPage/Flag_of_USA.jpg'
import Flag_of_Canada from './images/MainLandingPage/Flag_of_Canada.jpg'

import OnestopERP from "./images/MainLandingPage/5.png";
import GetStartedImmediately from "./images/MainLandingPage/4.png";
import ExceptionalSupport from "./images/MainLandingPage/8.png";
import TechnicalExcellence from "./images/MainLandingPage/1.png";
import InovativeConcept from "./images/MainLandingPage/3.png";
import CustomersCommitment from "./images/MainLandingPage/2.png";
import QualitySolution from "./images/MainLandingPage/7.png";
import EconomicValue from "./images/MainLandingPage/6.png";
import Centerside from "./images/MainLandingPage/23.png";

import mahalaxmilogo from "./images/MainLandingPage/mahalaxmilogo.png";
import AMRLogo from "./images/MainLandingPage/AMRLogo.png";
import GRthangamaligai from "./images/MainLandingPage/GRthangamaligai.png";
import EswarLogo from "./images/MainLandingPage/Eswar Jewellers logo.png";
import facebook_icon from "./images/MainLandingPage/facebook_icon.png";
import google_icon from "./images/MainLandingPage/google_icon.png";
import "./styles/changeThings.css";
import { brand } from "./ourBrands.js";
import SKJ_NEW_logo from './images/local/SKJ_NEW_logo.png';
import Le_Zilver_Logo from './images/MainLandingPage/LeZilver.png';
import Balaji_jewellers_Logo from './images/MainLandingPage/Balaji jewellers.png'
import MKM_Logo from "./images/MainLandingPage/MKM English_00.png";
import TechWallet from "./images/MainLandingPage/TechWallet.png";
import vizion from "./images/MainLandingPage/vizion.png";
import RagaDiamonds from "./images/MainLandingPage/RagaDiamonds.png";
import Dhananithi_Jewellers from "./images/MainLandingPage/Dhananithi Jewellers.png";


const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-0`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-5 lg:py-5`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center mx-10`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Headins = tw.h1`font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const SectionHeading = tw.h2`text-4xl sm:text-5xl font-bold tracking-wide text-center mt-4`;
const Subheading = tw.h5`font-bold  font-serif`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-justify font-bold`;

const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-5 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;

const OurPresenceImage = tw.img`relative `;
const OurPresenceParagraph = tw.div`text-white xl:text-lg text-justify font-bold absolute`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

//mainpage
const Containers = tw.div`relative w-full m-12 ml-0	`;
const TwoColums = tw.div`flex flex-col lg:flex-row lg:items-center mx-10`;
const LeftColumns = tw.div`lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumns = tw.div` mt-12 lg:mt-0 flex-1 flex flex-col items-stretch lg:self-end`;


const DecoratorBlob7 = styled(SvgDecoratorBlob7)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 -z-10`}
`;

const DecoratorBlob8 = styled(SvgDecoratorBlob8)`
  ${tw`pointer-events-none -z-10 absolute right-0 bottom-0 h-64 w-64 opacity-15 text-yellow-500`}
`;
const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const Cards = tw.div`flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full w-40 h-16 sm:h-20 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);
const TextInfo = tw.div`py-2 px-10 py-2`;
const TitleReviewContainer = tw.div`flex flex-col lg:justify-between sm:flex-row sm:justify-between sm:items-center lg:items-center`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 text-justify`;
const CardSlider = styled(Slider)`
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`flex justify-center mb-1`}
  }
`;
const SocialLink = styled.a`
  ${tw`cursor-pointer rounded-full text-gray-100 hover:bg-gray-700 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Controls = tw.div` items-center`;

AOS.init({
  duration: 1000,
});

export default ({
  roundedHeaderButton,
  testimonials = [
    {
      imageSrc: principles_long_term,
      quote:
        "Years of running a profitable organisation gives us a good sense of challenges that a growing business faces. We take pride in running a sustainable business that's energized by our customer.",
      customerName: "Long-Term Commitment",
    },
    {
      imageSrc: principles_focus,
      quote:
        "Software is our passion, and we back it up with our relentless investments in Innovation and R&D. So much so that we prefer to own the complete technology stack.",
      customerName: "Focus on Innovation, Research & Development",
    },
    {
      imageSrc: principles_customer_first,
      quote:
        "Over these years, It is our customer's trust and goodwill that has helped us establish a strong position in the market. No matter the size of your business, we provide solutions to grow.",
      customerName: "Customer-Centric Philosophy",
    },
  ],
  onboardingContent = [
    {
      imageSrc: migration_friendly,
      quote: "Our team helps in migrating the data from the legacy software.",
      title: "Migration Friendly",
      headColor: "#9f509a",
    },
    {
      imageSrc: Ready_to_use,
      quote:
        "Zinger solution is ready to use thus no time is wasted to learn the nuances of the jewellery business.",
      title: "Ready To Use",
      headColor: "#af559c",
    },
    {
      imageSrc: Dedicated,
      quote:
        "Professionally managed dedicated support ensuring faster solution.",
      title: "Dedicated Support",
      headColor: "#31a347",
    },
    {
      imageSrc: Infra_Ready,
      quote:
        "Cloud based software ensures no heavy expenses on infrastructure in premise.",
      title: "Infra Ready",
      headColor: "#2d9395",
    },
    {
      imageSrc: shortest_go_live,
      quote:
        "Zinger has the shortest go live process which can be as short as 3days.",
      title: "Shortest Go-Live",
      headColor: "#4ebfd5",
    },
  ],
  ourPresence = [
    {
      icon: Flag_of_India,
      iconTitle: "INDIA",
    },
    {
      icon: Flag_of_Bahrain,
      iconTitle: "BAHRAIN",
    },
    {
      icon: Flag_of_USA,
      iconTitle: "USA",
    },

  ],
  ourCountryPresence = [
    {
      icon: United_Arab_Emirates,
      iconTitle: "UAE",
    },
    {
      icon: Flag_of_Qatar,
      iconTitle: "QATAR",
    },
    {
      icon: Flag_of_Canada,
      iconTitle: "CANADA"
    }
  ],
  ourPresenceTitle = [
    {
      title: "Stitched in India",
    },
    {
      title: "Knitted for World",
    },
  ],
  cards = [
    {
      imageSrc: mahalaxmilogo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink:
        "https://www.facebook.com/pg/zinger.erp/reviews/?ref=page_internal",
      googleLink:
        "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Mahalaxmi Gold & Diamond Merchants",
      description: `<strong>Zinger team is very dedication</strong> by all means The migration jewelry software was very good, and they had sent a team to our showroom and did a <strong>tremendous job. The team is very responsive</strong> in terms of customization and happy to install their software for our showroom.`,
      feedbackUrl: 'https://www.youtube.com/embed/cWnzyZX8qak?si=IveFh683gWPwcNXI',
      isReadMore: false
    },
    {
      imageSrc: AMRLogo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink:
        "https://www.facebook.com/pg/zinger.erp/reviews/?ref=page_internal",
      googleLink:
        "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "AMR Sumangalee Jewellers",
      description: `We are <strong>well satisfied</strong> with <strong>Zinger ERP.</strong> It has been couple of years since we have started using this software, feature are extensive. Team keeps innovating <strong>new features</strong> that helps to enhance the business pertaining to jewelry business.Customer support is their USP and their team does an <strong>exemplary work.</strong> We are completely <strong>satisfied with Zinger</strong> and would recommend.`,
      feedbackUrl: 'https://www.youtube.com/embed/UHcGXlJdYd8?si=qn7LJw-2KOKlMzo3',
      isReadMore: false
    },
    {
      imageSrc: GRthangamaligai,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink:
        "https://www.facebook.com/pg/zinger.erp/reviews/?ref=page_internal",
      googleLink:
        "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "GR Thanga Maligai",
      description: `<strong>Zinger product is really great with easy options</strong> to use as an owner and for employees. Rasi team really helped in migrating from our old software to Zinger in <strong>24 hours time without any outage</strong> in our business. The best thing our employee like in this product is <strong>user-friendly in billing and reporting.</strong> And Rasi team is always approachable whenever we needed their help.`,
      isReadMore: false
    },
    {
      imageSrc: EswarLogo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink:
        "https://www.facebook.com/pg/zinger.erp/reviews/?ref=page_internal",
      googleLink:
        "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Eswar Jewellers",
      description: `It is very <strong>user-friendly</strong> to us. More customer support executives to give their relentless services. So <strong>I strongly recommend</strong> to anyone who want a complete software solutions.`,
      feedbackUrl: 'https://www.youtube.com/embed/IA8_p-x9cLw?si=O72ZokyHNHctGQij',
      isReadMore: false
    },
    {
      imageSrc: "https://images.zinger.co.in/customerlogo/Kt.png",
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink:
        "#",
      googleLink:
        "https://karthikthangamaligai.in/",
      title: "KARTHIK THANGAMALIGAI",
      description: `We searched many softwares for our new jewellery showroom. Based on my friend's recommendations we found <strong>Zinger Jewellery ERP </strong> from Rasi Infotech,Salem. We booked appointment & got the demo of the software. We were completetely satisfied with their product and service. Based on our usage we feel zinger software is very user-friendly, and their customer support is unimagined. Estimation, billing, and other jewellery need all are available in this single software. Zinger software reduces manpower dependency & reduces the operational cost. I'm happy on the purchase of this software. We strongly recommend Zinger Jewellery ERP . 

      Thank You`,
      feedbackUrl: 'https://www.youtube.com/embed/ZZdhZTKTgeQ?si=uwCL4LHtiPYfqrIh',
      isReadMore: false
    },
    {
      imageSrc: SKJ_NEW_logo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "SKJ Jewellers",
      description: `Our shop name is S Kandasamy & Sons from Karumathampatti.<strong> We are using Zinger software.</strong>It has all the requirement and things we need in jewellery software.The Zinger team is available anytime to provide support.Even when the store is crowded,they help us when we call. We have seen good results within two months of using this Zinger software.<strong>All customers visiting our first branch said their billing was clear and better than other stores.</strong>With Zinger team support we have started our second jewellery shop (2nd Branch). Zinger team development and customer support is best support.`,
      feedbackUrl: 'https://www.youtube.com/embed/Qxhge13QH2g?si=Sm07mgaDCnC5E5uu',
      isReadMore: false
    },
    {
      imageSrc: Le_Zilver_Logo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Le Zilver",
      description: `Hi My Name Is Venkatesh Ramesh We Are At Balaji Jewellery In Koramangala. Our Brand Name Is Le Zilver. Zinger Jewellery Software Have Been Supporting Our Brand. <strong>Zinger Software Is Very Easy To Use.</strong> Zinger Can Do Customization And Make It Very Supportive. I Give More Than 9.5 Points To Zinger. Thanks.`,
      feedbackUrl:  'https://www.youtube.com/embed/ZGJIDVIh0FM',
      isReadMore: false
    },
    {
      imageSrc: Balaji_jewellers_Logo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Balaji Jewellers",
      description: `Hello I Am Balaji Jewellers Form The Mallur Branch. We Started In Mallur Branch On 2023 April. At The Very First, We Used Zinger Software. <strong> It Is Very Userfriendly, And Easy To Use For All Staff Members, It's Service Is Also Excellent.</strong> Any Time 24/7. Whenever We Call, They Solve Our Doubts Through Online Instantly Like Live Demo Response. This Software Is More Userfriendly Compared To Other Software Which Is Installed To Another Branch. It's Service Is Excellent. Thank You To The Zinger Team.`,
      feedbackUrl:  'https://www.youtube.com/embed/VD7lpzdRkL8?si=BwuR6wna_Q29t9g5',
      isReadMore: false
    }, 
    {
      imageSrc: MKM_Logo,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "MKM THANGAMALIGAI",
      description: `Hello Everyone I Wish Everyone A Great Day This Day. Myself Mohammed Vajith, Designated Partner of MKM Thangamaligai LLP. Recently We Had Gone To Jewellery Fair For The Chennai Exhibition There, We Met The Zinger Software Team, Doing Jewellery ERP They Give The Entire Sollution In One Single Jewellery Software We Approached, The Company MD.Mr.Sashikumar, And Their Team That Zinger Team Will Co-operate Properly I'm Very Ipressed With The Software Modules And Mobile Apps And, This Software Has All Jewellery Requirments And Adopted To the Trendy One Easy to Use This Zinger Jewellery Software. The Team Has Traveled for the past 10 Days To Traub My Employee Without any HesitationWeekly 6 Days They Help Us Short out our Problems Through Digital Media. The Customer Support Team is Ready to Support on Sunday Also. We, Recommend Zinger Software To Any Other Jewellery Partner I Wish To Use This Software To Move Your Business To The Nest Level. I Wish All The Best TO The Zinger Software Team Thanks.`,
      feedbackUrl:  'https://www.youtube.com/embed/bIrigfCUWFU?si=u-ElJWZ9fylZz6en',
      isReadMore: false
    },
    {
      imageSrc: RagaDiamonds,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Raga Diamonds",
      description: `Hello I am Santoshkumar. Representing Raga Diamonds. We have used ZINGER's ERP in our store. We can say that ERP is very friendly. There are many ERPs in the market. We have tried this ERP. It is very user-friendly and we need it for everyone. why user-friendly software needed? All types of staff want easy understanding and comfort, then everyone comes and uses it One of the best, We searched and found Zinger ERP. Zinger ERP team support, it's marvellous. Just buying a ERP is not important.Many people are encountering team support challenges here.Team support is a key benefit of purchasing this software. They have separate team members designated for spport and development. If any requirements are not resolved within the set time frame, the company's MD, Mr.sashikumar, personally takes care of it and resolves it promptly. This practice has been a significant success for the company. In the jewelry industry, many people operate without using software,but nowadays, all processes are regulated under government norms. Every jewelry business requires software and should utilize it. This is where Zinger Jewelry ERP has made a significant impact in the industry. With Zinger already established globally, we extend our best wishes,and trust to Zinger and its team for achieving soon to great success in the industry. Thank You.`,
      feedbackUrl:  'https://www.youtube.com/embed/krxByQOAU7A?si=gWnUs5kMgTY1K7gg',
      isReadMore: false
    },
    {
      imageSrc: Dhananithi_Jewellers,
      facebookIcon: facebook_icon,
      googleIcon: google_icon,
      facebookLink: "#",
      googleLink: "https://www.google.com/search?q=zinger+erp&oq=zinger+erp&aqs=chrome..69i59l2j69i57j69i61l3j69i65l2.27646j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3babfb84b668b223:0x1124604944d4cdd9,1,,,",
      title: "Dhananithi Jewellers",
      description: `Hi, My shop name is Dhananithi Jewellers, We have opened a new shop in Vazhapadi,we have a billing software called Zinger Software.This Software was referred by our friends.When we called in the beginning, the response was very good.A week before the opening,they gave us a tag and all the support gave us, The Zinger team dedicated two days to training on this software.This software is the Best,so I have to tell this software to my friends also.Thank You.`,
      feedbackUrl:  'https://www.youtube.com/embed/ub91UC5xRg4?si=cgnzdHjitoVAIBRj',
      isReadMore: false
    }
  ],
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const [card,setCards]=useState(cards);
  const toggleReadMore = (index:number,isCheckFlag:Boolean) => {
    let newState = [...card];
    for (let i = 0; i < newState.length; i++) {
      newState[i].isReadMore= false
  }
    newState[index].isReadMore = !isCheckFlag;
    setCards(newState)
  };
  
  const [sliderRef, setSliderRef] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [name, setName] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const [invalidFlag, setInvalidFlag] = useState({
    name: false,
    phone: false,
    email: false,
    remarks: false,
  });

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = () => {
    const countryCodeListData = [];
    countryCodeListData.push({ value: "", label: "Select" });
    countryCodeLists.forEach((item) => {
      countryCodeListData.push({
        dial_code: item.dial_code,
        digits: item.digits ? item.digits : 15,
        value: item.dial_code,
        label: `${item.code}(${item.dial_code}) `,
        flag: item.flag,
        code: item.code,
      });
    });
    setCountryCodeList(countryCodeListData);
    const loginModal = reactLocalStorage.get("loginModal");
    if (!loginModal) {
      setTimeout(() => {
        setLoginModal(true);
        reactLocalStorage.set("loginModal", true);
      }, 10000);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const toggle = () => {
    setLoginModal(!loginModal);
    setNotificationModal(false);
    setName("");
    setCountryCode("+91");
    setPhone("");
    setEmail("");
    setRemarks("");
    setCaptchaSuccess(false);
    setInvalidFlag({
      name: false,
      phone: false,
      email: false,
      remarks: false,
    });
  };

  const toggleNotificationModal = () => {
    setNotificationModal(false);
  };

  const submitForm = () => {
    postAjaxRequest();
  };

  const postAjaxRequest = () => {
    if (validateFields() && captchaSuccess) {
      let phoneNo = "";
      if (selectedCountryCode) {
        phoneNo += `${selectedCountryCode}${phone}`;
      } else {
        phoneNo = phone;
      }
      const templateParams = {
        name: name,
        phone: phoneNo,
        remarks: remarks,
        email,
      };
      emailjs
        .send(
          "service_m6obie7",
          "template_aeildhh",
          templateParams,
          "user_6n2BpAAMVe0g19wywmeBb"
        )
        .then(
          (data) => {
            setNotificationModal(true);
            setName("");
            setCountryCode("+91");
            setPhone("");
            setEmail("");
            setRemarks("");
            setCaptchaSuccess(false);
            setLoginModal(false);
            setInvalidFlag({
              name: false,
              phone: false,
              email: false,
              remarks: false,
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const validateField = (
    fieldName: string,
    fieldValue: any,
    isRequired: boolean
  ) => {
    let isValid = false;
    if (isRequired === true) {
      isValid =
        fieldValue.toString().trim().length === 0
          ? false
          : fieldName.test(fieldValue.toString());
    } else {
      isValid =
        fieldValue.toString().trim().length > 0
          ? fieldName.test(fieldValue.toString())
          : true;
    }
    return isValid;
  };

  const validateFields = () => {
    let isValid = true;

    if (!validateField(/^[a-zA-Z0-9. ]+$/i, name, true)) {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: true }));
      isValid = false;
    } else {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: false }));
    }

    if (selectedCountryCode) {
      const telCodeFind = countryCodeList.filter(function (obj) {
        return obj.dial_code === selectedCountryCode;
      });
      const countryCodeDigits =
        telCodeFind.length > 0 ? telCodeFind[0].digits : 15;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        (countryCodeDigits !== phone.length && telCodeFind.length > 0)
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
        isValid = false;
      } else {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: false }));
      }
    } else {
      const countryCodeDigits = 10;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        countryCodeDigits !== phone.length
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
      }
    }

    return isValid;
  };

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "190px",
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 1000,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrow: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 768 ,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    // <AnimationRevealPage>
    <div>
      <Header roundedHeaderButton={roundedHeaderButton} isActiveTab="1" />
      <Containers>
        <TwoColums className="textandheader">
          <LeftColumns className="homepage2">
            <Headins tw="my-6" style={{fontSize:"2.5rem"}}>One Stop ERP Solution For Jewellery Outlets</Headins>
            <Paragraph>
              Automate your Jewellery operations & manage your business
              efficiently!
            </Paragraph>
          </LeftColumns>
          <RightColumns>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumns>
          <RightColumn>
              <h3  tw="font-bold" className="mx-auto"> Now Powered By</h3>
              <img
                className="wallet"
                src={TechWallet}
                alt="Design Illustration"
              />
                 <img
                className="wallet"
                src={vizion}
                alt="Design Illustration"
              />
          </RightColumn>
        </TwoColums>
      </Containers>
      <Container className="homepage changePage_bg">
        <Heading className="waviy_padding">
          <div className="waviy" data-aos="fade-right">
            We Change Things For The Better
          </div>
        </Heading>
        <Paragraph className="px-5" data-aos="fade-left">
          <div className="homepage2text">
            <p>
            We are your digital partners since 2016. We are a fast-growing solution provider in information technology, user experience and branding services for jewellery outlets across the world. With our ‘Digital Gene’ approach to business problems, we enable businesses to extract the most for their investments in digitalisation. We are a group of technical, creative, upbeat and committed developers who prioritize integrity in all our business relations. We strive to be excellent communicator, thinkers, and solution providers who are easy to understand and communicate with. Our team love what we do, with passion and great enthusiasm, and promise to make your experience with us,  a positive one always.
            </p>
          </div>
        </Paragraph>
        <Row className="w-100 margin-auto">
        <Col md="12" sm="12" xs="12" className="p-0">
            <FormGroup className="changePage_container">
              <div className="changePage_center_part">
                <img src={Centerside} alt="Center side" />
              </div>
              <div className="changePage_polygon rotate1">
                <div className="changePage_polygon_bg">
                  <img src={GetStartedImmediately} alt="one stop erp" />
                </div>
                <span className="rotate1_text">Get Start Immediately</span>
              </div>
              <div className="changePage_polygon rotate2">
                <div className="changePage_polygon_bg">
                  <img src={OnestopERP} alt="one stop erp" />
                </div>
                <span className="rotate2_text">One Stop all Module ERP</span>
              </div>
              <div className="changePage_polygon rotate3">
                <div className="changePage_polygon_bg">
                  <img src={InovativeConcept} alt="one stop erp" />
                </div>
                <span className="rotate3_text">Long Term Innovative Concepts</span>
              </div>
              <div className="changePage_polygon rotate4">
                <div className="changePage_polygon_bg">
                  <img src={CustomersCommitment} alt="one stop erp" />
                </div>
                <span className="rotate4_text">Customer Commitment</span>
              </div>
              <div className="changePage_polygon rotate5">
                <div className="changePage_polygon_bg">
                  <img src={QualitySolution} alt="one stop erp" />
                </div>
                <span className="rotate5_text">Customised Quality Solution</span>
              </div>
              <div className="changePage_polygon rotate6">
                <div className="changePage_polygon_bg">
                  <img src={EconomicValue} alt="one stop erp" />
                </div>
                <span className="rotate6_text">Highly Valued Economic Software</span>
              </div>
              <div className="changePage_polygon rotate7">
                <div className="changePage_polygon_bg">
                  <img src={TechnicalExcellence} alt="one stop erp" />
                </div>
                <span className="rotate7_text">Technical Excellence ERP</span>
              </div>
              <div className="changePage_polygon rotate8">
                <div className="changePage_polygon_bg">
                  <img src={ExceptionalSupport} alt="one stop erp" />
                </div>
                <span className="rotate8_text">All Time Exceptional Customer Support</span>
              </div>
            </FormGroup>
          </Col>
          {/* <Col md="12" sm="12" xs="12">
            <FormGroup className="changePage_container">
              <div className="changePage_center_part">
                <img src={Centerside} alt="Center side" />
              </div>
              <div className="changePage_polygon rotate1">
                <div className="changePage_polygon_bg">
                  <img src={GetStartedImmediately} alt="one stop erp" />
                </div>
                <span className="rotate1_text">Get Start Immediately</span>
              </div>
              <div className="changePage_polygon rotate2">
                <div className="changePage_polygon_bg">
                  <img src={OnestopERP} alt="one stop erp" />
                </div>
                <span className="rotate2_text">One Stop all Module ERP</span>
              </div>
              <div className="changePage_polygon rotate3">
                <div className="changePage_polygon_bg">
                  <img src={InovativeConcept} alt="one stop erp" />
                </div>
                <span className="rotate3_text">Long Term Innovative Concepts</span>
              </div>
              <div className="changePage_polygon rotate4">
                <div className="changePage_polygon_bg">
                  <img src={CustomersCommitment} alt="one stop erp" />
                </div>
                <span className="rotate4_text">Customer Commitment</span>
              </div>
              <div className="changePage_polygon rotate5">
                <div className="changePage_polygon_bg">
                  <img src={QualitySolution} alt="one stop erp" />
                </div>
                <span className="rotate5_text">Customised Quality Solution</span>
              </div>
              <div className="changePage_polygon rotate6">
                <div className="changePage_polygon_bg">
                  <img src={EconomicValue} alt="one stop erp" />
                </div>
                <span className="rotate6_text">Highly Valued Economic Software</span>
              </div>
              <div className="changePage_polygon rotate7">
                <div className="changePage_polygon_bg">
                  <img src={TechnicalExcellence} alt="one stop erp" />
                </div>
                <span className="rotate7_text">Technical Excellence ERP</span>
              </div>
              <div className="changePage_polygon rotate8">
                <div className="changePage_polygon_bg">
                  <img src={ExceptionalSupport} alt="one stop erp" />
                </div>
                <span className="rotate8_text">All Time Exceptional Customer Support</span>
              </div>
            </FormGroup>
          </Col> */}
        </Row>
      </Container>
      <Container>
        <ContentWithPaddingXl>
          <SectionHeading>
            The core values and principles that drive us
          </SectionHeading>
          <Testimonials>
            {testimonials.map((testimonial, index) => (
              <TestimonialContainer key={index}>
                <Testimonial>
                  <Image src={testimonial.imageSrc} />
                  <Subheading>{testimonial.customerName}</Subheading>
                  <Quote>"{testimonial.quote}"</Quote>
                </Testimonial>
              </TestimonialContainer>
            ))}
          </Testimonials>
        </ContentWithPaddingXl>
        {/* <DecoratorBlob7 /> */}
        {/* <DecoratorBlob8 /> */}
      </Container>
      <Container className="onboard_bg mt-5">
        <Content>
          <SectionHeading className="py-4">
            Onboarding Commitment
          </SectionHeading>
          <Row className="pb-5 w-100 m-auto">
            {onboardingContent.map((item, index) => (
              <>
                <Col md="6" sm="12" xs="12" tw="mb-4 lg:mb-0">
                  <Row className="onboard_container m-auto">
                    <Col md="3" sm="12" xs="12">
                      <img
                        src={item.imageSrc}
                        alt={item.title}
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col md="9" sm="12" xs="12">
                      <div
                        className="onboard_sub_heading"
                        style={{ color: item.headColor }}
                      >
                        {item.title}
                      </div>
                      <div className="onboard_paragraph">{item.quote}</div>
                    </Col>
                  </Row>
                </Col>
                <Col md="6"></Col>
                <Col md="6"></Col>
              </>
            ))}
          </Row>
        </Content>
      </Container>
      <Container className="customerVoice_bg mb-2">
        <Content className="py-5">
          <SectionHeading className="mb-5 mt-0">Customer Voice</SectionHeading>
          <Row className="">
            <Col md="12" sm="12" xs="12">
              <FormGroup row>

                <Col md="12" sm="12" xs="12">
                  <CardSlider
                    ref={setSliderRef}
                    autoplay={true}
                    {...sliderSettings}
                  >
                    {card.map((card, index) => (
                      <div>
                        <Row>
                          <Col md="6">
                            <Cards key={index} className="testimonals" >
                              <CardImage
                                className="mx-auto customer-img"
                                imageSrc={card.imageSrc}
                              />
                              <TextInfo className="">
                                {card.isReadMore ? <Description
                                  className="data-expandable data-expand-text"
                                  dangerouslySetInnerHTML={{
                                    __html: card.description,
                                  }}
                                ></Description> : <>
                                  <Description
                                    className="data-expandable data-expand-text"
                                    dangerouslySetInnerHTML={{
                                      __html: card.description.substring(0,100),
                                    }}
                                  ></Description></>}
                                <span
                                  onClick={() => toggleReadMore(index,card.isReadMore)}
                                  className="read-or-hide"
                                  style={{ color: "blue" }}
                                >
                                  {card.isReadMore ? "show less" : " ...read more"}
                                </span>
                                <hr className="customer_hr" />
                                <TitleReviewContainer>
                                  <h5 className="testimonalsHeader mx-auto">
                                    {card.title}
                                    <span>
                                      <SocialLink
                                        href={card.facebookLink}
                                        target="_blank"
                                      >
                                        <img
                                          src={card.facebookIcon}
                                          alt="face_book"
                                        />
                                      </SocialLink>
                                    </span>
                                    <span>
                                      <SocialLink
                                        href={card.googleLink}
                                        target="_blank"
                                      >
                                        <img src={card.googleIcon} alt="google" />
                                      </SocialLink>
                                    </span>
                                  </h5>
                                </TitleReviewContainer>
                              </TextInfo>
                            </Cards>
                          </Col>
                          <Col md="6">
                            <div>
                              <iframe width="100%" height="350px" src={card.feedbackUrl} title="Zinger Erp Feedback Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </CardSlider>
                  <Controls className="text-center mt-2">
                    <PrevButton
                      className="testimonalsControlBtn"
                      onClick={sliderRef?.slickPrev}
                    >
                      <ChevronLeftIcon />
                    </PrevButton>
                    <NextButton
                      className="testimonalsControlBtn"
                      onClick={sliderRef?.slickNext}
                    >
                      <ChevronRightIcon />
                    </NextButton>
                  </Controls>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Content>
      </Container>
      {/* Our Valued Customers start */}
      <div className="content" md="12" sm="12" xs="12" >
        <h1 className="header"> Our Valued Customers</h1>
        <div>
          <Slider {...settings}>
            {brand.map((item) => (
              <div>
                <div className="img-body">
                  <img src={item.Image} alt="" />
                </div>
                <div className="Title">
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
       {/* Our Valued Customers end */}
      <Container className="ourpresence_bg">
        <Content >
            <Col md="12" sm="12" xs="12" tw="w-full p-0">
              <SectionHeading className="py-3 sm:py-3 md:py-4 lg:py-4 m-0">
                Our Presence
              </SectionHeading>
              <OurPresenceImage src={Map} />
              <OurPresenceParagraph className="ourpresence_text ">
                  <div tw="py-2 sm:py-5 px-2 sm:px-3 md:py-8 lg:py-10">
                    {ourPresenceTitle.map((items, index) => (
                      <p key={index} className="mb-1 ourpresence_header">
                        {items.title}
                      </p>
                    ))}
                  </div>
              <Row>
                <Col md="3" sm="3" xs="3">
                    {ourPresence.map((item, index) => (
                      <p key={index} className="ourpresence_text_style">
                        <span>
                          <img
                            className="ourpresence_flag"
                            src={item.icon}
                            alt="flag"
                          />
                        </span>
                        {item.iconTitle}
                      </p>
                    ))}
                </Col>
                <Col md="3" sm="3" xs="3">
                  {ourCountryPresence.map((item, index) => (
                    <p key={index} className="ourpresence_text_style">
                      <span>
                        <img
                          className="ourpresence_flag"
                          src={item.icon}
                          alt="flag"
                        />
                      </span>
                      {item.iconTitle}
                    </p>
                  ))}
                </Col>
              </Row>
              </OurPresenceParagraph>
            </Col>
        </Content>
      </Container>
      <Footer />
      {/* Welcome modal start */}
      <Modal isOpen={loginModal}>
        <ModalHeader toggle={toggle} className="p-2">
          <h5 className="text-center mb-0">Schedule a demo</h5>
        </ModalHeader>
        <ModalBody className="px-5">
          <Form>
            <Row className="mb-2">
              <Col md="4">
                <Label for="name">Name :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  invalid={invalidFlag.name}
                  placeholder="Name"
                  bsSize="sm"
                  onChange={(e) => {
                    setName(e.target.value);
                    setInvalidFlag((invalidFlag) => ({
                      ...invalidFlag,
                      name: false,
                    }));
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="phone">Mobile :</Label>
              </Col>
              <Col md="8">
                <InputGroup Row>
                  <Col md="4" sm="4" xs="4">
                    <Input
                      type="select"
                      id="selectedCountryCode"
                      bsSize="sm"
                      value={selectedCountryCode}
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countryCodeList.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md="8" sm="8" xs="8">
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={phone}
                      invalid={invalidFlag.phone}
                      placeholder="mobile"
                      bsSize="sm"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setInvalidFlag((invalidFlag) => ({
                          ...invalidFlag,
                          phone: false,
                        }));
                      }}
                      style={{ borderTopLeftRadius: 0, borderLeftRadius: 0 }}
                    />
                  </Col>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="email">Email :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  invalid={invalidFlag.email}
                  placeholder="Email"
                  bsSize="sm"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setInvalidFlag((invalidFlag) => ({
                      ...invalidFlag,
                      email: false,
                    }));
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="Text">Requirement Details :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="textarea"
                  name="remarks"
                  id="remarks"
                  value={remarks}
                  invalid={invalidFlag.remarks}
                  placeholder="Requirement Details"
                  bsSize="sm"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4"></Col>
              <Col md="8">
                <Captcha onChange={(status) => setCaptchaSuccess(status)} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4"></Col>
              <Col md="8">
                <Button
                  block
                  size="sm"
                  color="info"
                  className="btn"
                  onClick={submitForm}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* Welcome modal end */}
      {/* Notification modal start */}
      <Modal isOpen={isNotificationModal}>
        <ModalHeader className="p-2 text-center modal-success">
          Send Mail
        </ModalHeader>
        <ModalBody className="py-3 text-center">
          <div>
            <i
              className="fa fa-thumbs-up"
              color="success"
              style={{ fontSize: "50px", color: "#4dbd74" }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <h4>Thank you!</h4>
          </div>
          <div>
            Your message has been successfully sent. We will contact you very
            soon!
          </div>
        </ModalBody>
        <ModalFooter>
          <Col md="4">
            <Button
              block
              size="sm"
              color="info"
              className="btn"
              onClick={toggleNotificationModal}
            >
              OK
            </Button>
          </Col>
        </ModalFooter>
      </Modal>

      {/* Notification modal end */}
    </div>

  );
};
