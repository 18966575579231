import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  Input,
  InputGroup,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import emailjs from "emailjs-com";
import Captcha from "react-numeric-captcha";
import { countryCodeLists } from "./CountryCodeListJson.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import Demo from "../images/Testimonials/demo.png";
import Google from "../images/demo/google.png";
import Facebook from "../images/demo/facebook.png";

const Container = tw.div`relative`;
const Heading = tw.h3`w-full`;
const Paragraph = tw.p`mb-5 lg:mb-8 text-base xl:text-lg`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto mt-3 md:mt-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 mb-10`;
const Description = tw.p`mt-4 pr-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

export default () => {
  const [name, setName] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const [invalidFlag, setInvalidFlag] = useState({
    name: false,
    phone: false,
    email: false,
    remarks: false,
  });

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = () => {
    const countryCodeListData = [];
    countryCodeListData.push({ value: "", label: "Select" });
    countryCodeLists.forEach((item) => {
      countryCodeListData.push({
        dial_code: item.dial_code,
        digits: item.digits ? item.digits : 15,
        value: item.dial_code,
        label: `${item.code}(${item.dial_code}) `,
        flag: item.flag,
        code: item.code,
      });
    });
    setCountryCodeList(countryCodeListData);
  };

  const toggleNotificationModal = () => {
    setNotificationModal(false);
  };

  const submitForm = () => {
    postAjaxRequest();
  };

  const postAjaxRequest = () => {
    if (validateFields() && captchaSuccess) {
      let phoneNo = "";
      if (selectedCountryCode) {
        phoneNo += `${selectedCountryCode}${phone}`;
      } else {
        phoneNo = phone;
      }
      const templateParams = {
        name: name,
        phone: phoneNo,
        remarks: remarks,
        email: "",
      };
      emailjs
        .send(
          "service_m6obie7",
          "template_aeildhh",
          templateParams,
          "user_6n2BpAAMVe0g19wywmeBb"
        )
        .then(
          (data) => {
            setNotificationModal(true);
            setName("");
            setCountryCode("+91");
            setPhone("");
            setEmail("");
            setRemarks("");
            setCaptchaSuccess(false);
            setInvalidFlag({
              name: false,
              phone: false,
              remarks: false,
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const resetForm = () => {
    setName("");
    setCountryCode("+91");
    setPhone("");
    setEmail("");
    setRemarks("");
    setCaptchaSuccess(false);
    setInvalidFlag({
      name: false,
      phone: false,
      remarks: false,
    });
  };

  const validateField = (
    fieldName: string,
    fieldValue: any,
    isRequired: boolean
  ) => {
    let isValid = false;
    if (isRequired === true) {
      isValid =
        fieldValue.toString().trim().length === 0
          ? false
          : fieldName.test(fieldValue.toString());
    } else {
      isValid =
        fieldValue.toString().trim().length > 0
          ? fieldName.test(fieldValue.toString())
          : true;
    }
    return isValid;
  };

  const validateFields = () => {
    let isValid = true;

    if (!validateField(/^[a-zA-Z0-9. ]+$/i, name, true)) {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: true }));
      isValid = false;
    } else {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: false }));
    }

    if (selectedCountryCode) {
      const telCodeFind = countryCodeList.filter(function (obj) {
        return obj.dial_code === selectedCountryCode;
      });
      const countryCodeDigits =
        telCodeFind.length > 0 ? telCodeFind[0].digits : 15;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        (countryCodeDigits !== phone.length && telCodeFind.length > 0)
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
        isValid = false;
      } else {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: false }));
      }
    } else {
      const countryCodeDigits = 10;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        countryCodeDigits !== phone.length
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
      }
    }

    return isValid;
  };

  return (
    <AnimationRevealPage>
      <Header isActiveTab="6" />
      <Container>
        <TwoColumn className="textandheader p-2">
          <TextColumn className="demoanimation">
            <Heading>Get a Personalised Demo</Heading>
            <img src={Demo} alt="Demo" width="60%" />
            <Paragraph>
              Want to see how Zinger can help you manage your jewellery
              efficiently and grow your business?
            </Paragraph>
            <Paragraph>
              Fill out the form & our team will get in touch with you to
              schedule a one-on-one demo which is free of cost and done online.
            </Paragraph>
            <Row>
              <Col md="12" sm="12" xs="12">
                <h1 style={{ fontFamily: "Praise, cursive", fontSize: "50px" }}>
                  We are happy to announce !!!
                </h1>
              </Col>
              <Col md="6" sm="12" xs="12">
                <img src={Google} alt="Google" className="my-1" />
              </Col>
              <Col md="6" sm="12" xs="12">
                <img
                  src={Facebook}
                  alt="Facebook"
                  className="my-1"
                />
              </Col>
            </Row>
          </TextColumn>
          <TextColumn className="demoanimation">
            <Heading>
              Finally, a Jewellery POS Software that’s powerful & easy to use!
            </Heading>
            <Description>
              Learn how we can help in growing your Jewellery Business!
            </Description>
            <Row className="mt-5">
              <Col md="1"></Col>
              <Col md="10">
                <Card
                  style={{
                    borderRadius: "25px 0px 25px 0px",
                    background: "#D4E7F8",
                  }}
                >
                  <CardBody className=" card-body">
                    <Form>
                      <FormGroup className="mb-1">
                        <Label for="name">Name :</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          invalid={invalidFlag.name}
                          placeholder="Name"
                          bsSize="sm"
                          onChange={(e) => {
                            setName(e.target.value);
                            setInvalidFlag((invalidFlag) => ({
                              ...invalidFlag,
                              name: false,
                            }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <Label for="phone">Mobile Number :</Label>
                        <InputGroup Row>
                          <Col md="4" sm="3" xs="4">
                            <Input
                              type="select"
                              id="selectedCountryCode"
                              bsSize="sm"
                              value={selectedCountryCode}
                              className="col-md-3"
                              onChange={(e) => setCountryCode(e.target.value)}
                              style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            >
                              {countryCodeList.map((item) => (
                                <option value={item.value}>{item.label}</option>
                              ))}
                            </Input>
                          </Col>
                          <Col md="8" sm="9" xs="8">
                            <Input
                              type="text"
                              name="phone"
                              id="phone"
                              value={phone}
                              invalid={invalidFlag.phone}
                              placeholder="Phone"
                              bsSize="sm"
                              onChange={(e) => {
                                setPhone(e.target.value);
                                setInvalidFlag((invalidFlag) => ({
                                  ...invalidFlag,
                                  phone: false,
                                }));
                              }}
                              style={{
                                borderTopLeftRadius: 0,
                                borderLeftRadius: 0,
                              }}
                            />
                          </Col>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <Label for="email">Email :</Label>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          invalid={invalidFlag.email}
                          placeholder="Email"
                          bsSize="sm"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setInvalidFlag((invalidFlag) => ({
                              ...invalidFlag,
                              email: false,
                            }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="Text">Requirement Details :</Label>
                        <Input
                          type="textarea"
                          name="remarks"
                          id="remarks"
                          value={remarks}
                          invalid={invalidFlag.remarks}
                          placeholder="Requirement Details"
                          bsSize="sm"
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      </FormGroup>
                      <Row className="rnc-center">
                        <Col md="12">
                          <Captcha
                            onChange={(status) => setCaptchaSuccess(status)}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row className="mb-2">
                      <Col md="6">
                        <Button
                          block
                          size="sm"
                          color="danger"
                          className="btn"
                          onClick={resetForm}
                        >
                          <b>Reset</b>
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          block
                          size="sm"
                          color="primary"
                          className="btn"
                          onClick={submitForm}
                        >
                          <b>Submit</b>
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Footer />
      {/* Notification modal start */}
      <Modal isOpen={isNotificationModal}>
        <ModalHeader className="p-2 text-center modal-success">
          Send Mail
        </ModalHeader>
        <ModalBody className="py-3 text-center">
          <div>
            <i
              className="fa fa-thumbs-up"
              color="success"
              style={{ fontSize: "50px", color: "#4dbd74" }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <h4>Thank you!</h4>
          </div>
          <div>
            Your message has been successfully sent. We will contact you very
            soon!
          </div>
        </ModalBody>
        <ModalFooter className="p-0">
          <Col md="4">
            <Button
              block
              size="sm"
              color="info"
              className="btn"
              onClick={toggleNotificationModal}
            >
              OK
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
      {/* Notification modal end */}
    </AnimationRevealPage>
  );
};
