import React, { useState, useRef, useEffect, createRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import scrollToComponent from "react-scroll-to-component";
import { FormGroup, Nav, NavItem, NavLink, Col, Row } from "reactstrap";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import { FeatuersList } from "./FeaturesList.js";
import WebSitepage2 from "../images/features/Website-02.png";
import defaultCardImage from "../images/features/shield-icon.svg";
import ThirdParty from "../images/features/ThirdParty.png";


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-5 md:py-5`}
`;
const Heading = tw.h3`w-full text-left`;

// const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const HeroContainer = tw.div`relative mx-auto h-full flex flex-col`;

export default () => {
  const [activeTab, setActiveTab] = useState("1");

  const elementsRef = useRef(FeatuersList.map(() => createRef()));

  const toggle = (isActiveTab) => {
    setActiveTab(isActiveTab);
  };

  const handleScroll = () => {
    let wrapper = document.getElementById("wrapper"),
      main = document.getElementById("main"),
      sidebar = document.getElementById("sc");
    document
      .getElementById("sc")
      .setAttribute(
        "style",
        "display:block;width:" +
          document.getElementById("sidebar").offsetWidth +
          "px"
      );
    document.getElementById("sc").style.width =
      document.getElementById("sidebar").offsetWidth;
    //if sidebar smaller than main
    if (sidebar.offsetHeight < main.offsetHeight) {
      //if sidebar smaller than screen - stick to top rather than bottom
      if (sidebar.offsetHeight < window.innerHeight) {
        if (
          wrapper.getBoundingClientRect().bottom < window.innerHeight &&
          wrapper.getBoundingClientRect().bottom < sidebar.offsetHeight
        ) {
          wrapper.classList.remove("fix-top-VP");
          wrapper.classList.add("flex-bottom");
        } else if (wrapper.getBoundingClientRect().top < 0) {
          wrapper.classList.add("fix-top-VP");
          wrapper.classList.remove("flex-bottom");
        } else {
          wrapper.classList.remove("fix-top-VP");
          wrapper.classList.remove("flex-bottom");
        }
      }
      //if wrapper taller than sidebar - stick to bottom
      else if (sidebar.offsetHeight < wrapper.offsetHeight) {
        if (wrapper.getBoundingClientRect().bottom < window.innerHeight) {
          wrapper.classList.remove("fix-bottom-VP");
          wrapper.classList.add("flex-bottom");
        } else if (
          wrapper.getBoundingClientRect().bottom >
          sidebar.offsetHeight + window.innerHeight
        ) {
          wrapper.classList.remove("fix-bottom-VP");
          wrapper.classList.remove("flex-bottom");
        } else {
          wrapper.classList.add("fix-bottom-VP");
          wrapper.classList.remove("flex-bottom");
        }
      }
    }
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeActiveTab = (Tab) => {
    setActiveTab(Tab);
  };

  return (
    <div>
      <Header isActiveTab="2" />
      <Container>
        <HeroContainer>
          <img className="background_img" src={WebSitepage2} alt="background" />
          <FormGroup row className="backgoundtextfeatures">
            <Col md="7" sm="7" xs="7">
              <div>
                <h2 className="background_heading">
                  Features That Make Selling Easier
                </h2>
                <p className="background_paragraph">
                  Zinger provides powerful Integration with the most popular
                  platforms needed for the smooth functioning of your Jewellery
                  business.
                </p>
              </div>
            </Col>
          </FormGroup>
        </HeroContainer>
      </Container>
      <Container>
        <Row className="m-0 mt-4" id="wrapper">
          <Col
            sm="3"
            md="3"
            id="sidebar"
            className="sidebar p-0 d-none d-sm-block"
          >
            <Nav
              tabs
              vertical
              pills
              className="sidebar1 textandheader features-font-weight-bold"
              id="sc"
            >
              <NavItem>
                <NavLink
                  className={
                    activeTab === "1" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[0], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("1");
                  }}
                >
                  Real-time integration of HO & Branches <br/>
                  <small>(Multi-branch)</small>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "2" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[1], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                    });
                    toggle("2");
                  }}
                >
                  Estimation & Billing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "3" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[2], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("3");
                  }}
                >
                 Stock & RFID Inventory Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "4" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[3], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("3");
                  }}
                >
                 Asset Tracking Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "5" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[4], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("4");
                  }}
                >
                 Chit & Schemes Module
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "6" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[5], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("5");
                  }}
                >
                 Dealer Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "7" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[6], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("6");
                  }}
                >
                  Order Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "8" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[7], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("7");
                  }}
                >
                  Repair Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "9" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[8], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("8");
                  }}
                >
                  Karighar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "10" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[9], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("9");
                  }}
                >
                 Accounting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "11" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[10], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("10");
                  }}
                >
                 Staff (Sales) Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "12" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[11], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("11");
                  }}
                >
                 Purchase Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "13" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[12], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("12");
                  }}
                >
                  HR & Payroll Module
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "14" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[13], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("13");
                  }}
                >
                  Non-Sale Stock Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "15" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[14], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("14");
                  }}
                >
                  Customer Relationship Management & it's Analysis
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "16" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[15], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("15");
                  }}
                >
                  Feedback Management & it's Analysis
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "17" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[16], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("16");
                  }}
                >
                 Enquiry & Follow-up Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "18" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[17], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("17");
                  }}
                >
                 Field Sales Management 
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "19" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[18], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("18");
                  }}
                >
                  Location Tracking Module
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "20" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[19], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("19");
                  }}
                >
                 Scheduled Reports & Notifications
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "21" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[20], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("20");
                  }}
                >
                  User Entitlement Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "22" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[21], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("21");
                  }}
                >
                  Track Critical Software Activity Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "23" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[22], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("22");
                  }}
                >
                  Loyalty & Reward Programs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "24" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[23], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("23");
                  }}
                >
                  Whatsapp / E-mail / SMS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "25" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[24], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("24");
                  }}
                >
                  Third Party Software Integration
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "26" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[25], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("25");
                  }}
                >
                   Third Party Hardware Integration
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "27" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[26], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("26");
                  }}
                >
                  HUID (Hallmarking Unique Identification)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "28" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[27], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("27");
                  }}
                >
                Multi-Lingual & Multi-Currency Facility 
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "29" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[28], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("28");
                  }}
                >
                  VR-Based Solution & Online Video
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "30" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[29], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("29");
                  }}
                >
                    E-Commerce / E-Brochure Service
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "31" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[30], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("30");
                  }}
                >
                Marketing 360<sup>0</sup> Solution
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "32" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[31], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("31");
                  }}
                >
                  Online Payment Module Integration
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "33" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[32], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("32");
                  }}
                >
                 Online Video Demo Module
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "34" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[33], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("33");
                  }}
                >
                  MIS Reports
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "35" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[34], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("34");
                  }}
                >
                  Mobile Apps (Android & IOS)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "36" ? "feature_active_tab" : "feature_tab"
                  }
                  onClick={() => {
                    scrollToComponent(elementsRef.current[35], {
                      offset: 0,
                      align: "top",
                      duration: 500,
                      ease: "inExpo",
                    });
                    toggle("35");
                  }}
                >
                 Customizable Dashboard
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12" sm="9" md="9" id="main" className="sidebar_hide_xs">
            {FeatuersList.map((featureData, i) => (
              <div key={i}>
                <section
                  ref={(section) => {
                    elementsRef.current[i] = section;
                  }}
                >
                  <Container onMouseEnter={() => changeActiveTab(`${i + 1}`)}>
                    <ThreeColumnContainer>
                      <Heading className="textandheader animationRight text-center">
                        {featureData.name}
                      </Heading>
                      <span className="pb-3  text-center w-100">{featureData.describe}</span>
                {featureData.name === 'Online Payment Module Integration' ? 
                    <Column key={i} className="p-3 w-100">
                        <div className="tabcontent" data-aos="fade-left">
                            <Col md="12" xs="12" sm="12">
                                <img
                                  src={ThirdParty}
                                  alt="payment-gatway"
                                  
                                  className="tabcontent1 w-100"
                                />
                            </Col>
                        </div>
                      </Column> 
                      :
                      ''                    
                    }
                    {featureData.data.map((item, i) => (
                        <Column key={i} className="p-3 w-100">
                        <div className="tabcontent" data-aos="fade-left">
                          <Row className="justify-content-center">
                            <Col md="4" xs="4" sm="4">
                              <div className="featureIcons">
                                <img
                                  src={item.imageSrc || defaultCardImage}
                                  alt={item.title}
                                  width="60px"
                                  className="tabcontent1"
                                />
                              </div>
                            </Col>
                          </Row>
                          <span>
                            <h4 className="text-center featureIcon font-weight-bold textandheader" style={{fontWeight: 'bold'}}>
                              <span className="title">{item.title}</span>
                            </h4>
                            {/* <p className="text-justify px-3 featureIcon textandheader">
                              <span className="title">
                                {item.description}
                              </span>
                            </p> */}
                          </span>
                        </div>
                      </Column>               
                      ))}
                    </ThreeColumnContainer>
                  </Container>
                </section>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
