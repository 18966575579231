
const brand = [
  {
    Image: "https://images.zinger.co.in/customerlogo/AMR.png",
    title:"AMR Sumangalee Jewellers",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Fareeda.png",
    title:"Fareeda Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Mahalaxmi.png",
    title:"Mahalaxmi Gold & Diamond Merchants",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/EswarJewellers.png",
    title:"Eswar Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/SKJ_NEW_logo.png',
    title:"SKJ Jewellers",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Raga.png",
    title:"Raga Diamond Jewels",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/AGSTamil.png",
    title:"AGS Thanga Maligai",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/KVB.png",
    title:"Karur Vysya Bank",
  },
  // {
  //   Image: "https://images.zinger.co.in/customerlogo/PMP.png",
  //   title:"PMR Jewellery",
  // },

  {
    Image: "https://images.zinger.co.in/customerlogo/SSVJ.png",
    title:"SSVJ Jewellers",
  },

  {
    Image: "https://images.zinger.co.in/customerlogo/Sreevenkateswara.png",
    title:"Sree Venkateswara Jewellers",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Ganga.png",
    title:"Ganga Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Sibiraja.png",
    title:"Sibiraja Nagai Maligai ",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/DJjewellers.png",
    title:"DJ jewellers",
  },
  // {
  //   Image: "https://images.zinger.co.in/customerlogo/SKJEnglish.png",
  //   title:"SKJ Jewellery",
  // },

  {
    Image: 'https://images.zinger.co.in/customerlogo/7.png',
    title:"SPV Jewellers",
  },
  
  {
    Image: 'https://images.zinger.co.in/customerlogo/PMR_New_Logo.png',
    title:"PMR Venkateswara Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/JVS.png",
    title:"JVS Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Muthucharam.png",
    title:"Muthucharam Jewellery",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/5.png',
    title:"Sri Swamy Jewellers",
  },
  // {
  //   Image: "https://images.zinger.co.in/customerlogo/SPV.png",
  //   title:"SPV Jewellers",
  // },
  {
    Image: 'https://images.zinger.co.in/customerlogo/8.png',
    title:"Princess Jewelry",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Kt.png",
    title:"Karthik Thangamaligai",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/KKJ.png",
    title:"KKJ Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/KK.png",
    title:"KK Jewellery",
  }, 
  {
    Image: "https://images.zinger.co.in/customerlogo/SalemSilverPalace.png",
    title:"Salem Silver Palace",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/Balaji.png",
    title:"Balaji Jewellery",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/leZilver.png",
    title:"Balaji Jewellers (Le Zilver)",
  },
  {
    Image: "https://images.zinger.co.in/customerlogo/cosmos.png",
    title:"Cosmos Diamonds",
  },

  {
    Image: 'https://images.zinger.co.in/customerlogo/2.png',
    title:"Dhana Jewellery Mart",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/1.png',
    title:"SSJ India",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/3.png',
    title:"Annamalai Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/4.png',
    title:"Sona Mahal",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/5.png',
    title:"Sri Swamy Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/6.png',
    title:"Krishna Jewellers",
  },
  //New_client
  {
    Image: 'https://images.zinger.co.in/customerlogo/sumangalee_cbe.jpeg',
    title:"Sumangalee Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/VP.jpg',
    title:"VP GOLD",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/MKMEnglish_00.png',
    title:"MKM Thangamaligai",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/jnr.jpg',
    title:"JNR Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/baskara.jpeg',
    title:"Baskara Silvers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/SaravanasJewellers.png',
    title:"Saravana Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/PRLogo.png',
    title:"PR Gold & Silver",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/SriShanmugaJewellery.png',
    title:"Shanmuga Jewellers",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/GomathiShankarThangamaligai.png',
    title:"Gomathi Shankar Thangamaligai",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/karat.jpeg',
    title:"Carat & Karat",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/sks.jpg',
    title:"SKS",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/dhananithy.jpg',
    title:"dhana Nithy",
  },
  {
    Image: 'https://images.zinger.co.in/customerlogo/jai_maruthi.jpg',
    title:"jai Maruthi",
  }
  
]

export { brand }