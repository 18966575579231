import FastBilling from "../images/features/FastBilling.png";
import BluetoothPrinter from "../images/features/BluetoothPrinter.png";
import DiscountManagemnet from "../images/features/DiscountManagemnet.png";
import MutipleModes from "../images/features/MutipleModes.png";
import Quotations from "../images/features/Quotations.png";
import SalesApp from "../images/features/SalesApp.png";
import Barcode from "../images/features/Barcode.png";

import StatusOverview from "../images/features/StatusOverview.png";
import PurchaseOrders from "../images/features/PurchaseOrders.png";
import ProfessionalPurchaseOrders from "../images/features/ProfessionalPurchaseOrders.png";
import AssetManagemnet from "../images/features/ring.png";
import StockMovement from "../images/features/StockMovement.png";

import ManageDocument from "../images/features/ManageDocument.png";
import Expenses from "../images/features/Expenses.png";
import Expenditure from "../images/features/Expenditure.png";
import CreditandDebitDashboard from "../images/features/CreditandDebitDashboard.png";
import ManageTaxableInvoices from "../images/features/ManageTaxableInvoices.png";
import Reports from "../images/features/Reports.png";

import SchemeAnalysis from "../images/features/SchemeAnalysis.png";
import SavingScheme from "../images/features/SavingScheme.png";

import VipProgram from "../images/features/VipProgram.png";
import CustomIntegration from "../images/features/CustomIntegration.png";
import LoyaityManagement from "../images/features/LoyaityManagement.png";

import CustomerOverview from "../images/features/CustomerOverview.png";
import Contact from "../images/features/Contact.png";
import PurchasingHistory from "../images/features/PurchasingHistory.png";

import FollowupManagement from "../images/features/FollowupManagement.png";
import Enquiries from "../images/features/Enquiries.png";

import ProductFeedback from "../images/features/ProductFeedback.png";
import FeedbackAnalysis from "../images/features/FeedbackAnalysis.png";
import SalesFeedback from "../images/features/SalesFeedback.png";

// import Demo from "../images/features/Demo.png";

import TimeManagement from "../images/features/TimeManagement.png";
import Sales from "../images/features/Sales.png";

import MultiLocations from "../images/features/MultiLocations.png";

import LeaveManagement from "../images/features/LeaveManagement.png";
import Attendance from "../images/features/Attendance.png";
import PayrollManagement from "../images/features/PayrollManagement.png";
import EmployeeManagement from "../images/features/EmployeeManagement.png";

import Module from "../images/features/Module.png";
import QualityEvaluation from "../images/features/QualityEvaluation.png";
import StandardizationOfMetal from "../images/features/StandardizationOfMetal.png";
import MetalToOrnamentConversion from "../images/features/MetalToOrnamentConversion.png";


import KPI from "../images/features/KPI.png";
import BusinessIntelligence from "../images/features/BusinessIntelligence.png";
import RepairModule from "../images/features/RepairModule.png";

import SocialMediaIntegration from "../images/features/SocialMediaIntegration.png";
import InstantPayment from "../images/features/InstantPayment.png";
import PaymentSolution from "../images/features/PaymentSolution.png";

import TallyIntegration from "../images/features/TallyIntegration.png";

import RFID from "../images/features/RFID.png";
import Integration from "../images/features/Integration.png";
import WeighScale from "../images/features/WeighScale.png";

import ProgrammerManagement from "../images/features/ProgrammerManagement.png";
import CouponManagement from "../images/features/CouponManagement.png";

import Setting from "../images/features/Setting.png";

import AutomatedReminder from "../images/features/AutomatedReminder.png";
import Notification from "../images/features/Notification.png";

import VideoCall from "../images/features/VideoCall.png";
import VirtualReality from "../images/features/VirtualReality.png";

import DealerManagement from "../images/features/DealerManagement.png";
import OrderManagement from "../images/features/Order Management.png";

import StaffManagement from "../images/features/Staff (Sales) Management.png";
import PurchaseManagement from "../images/features/Purchase Management.png";
import NonSaleStockManagement from "../images/features/Non-Sale Stock Management.png";
import FieldSalesManagement from "../images/features/Field Sales Management.png";
import LocationTrackingModule from "../images/features/Location Tracking Module.png";

import TrackCriticalSoftwareActivityLogs from "../images/features/Track Critical Software Activity Logs.png";
import WhatsappEmailSMS from "../images/features/Whatsapp E-mail SMS.png";
import HUID from "../images/features/HUID.png";

import Marketing360oSolution from "../images/features/Marketing 360o Solution.png";
import ECommerce from "../images/features/E Commerce E Brochure Service.png";
import Dashboard from "../images/features/Dashboard.png";

import business360app from "../images/features/business360app.png";
import productcatalogueapp from "../images/features/productcatalogueapp.png";
import savingsapp from "../images/features/savingsapp.png";
import softretail from "../images/features/softretail.png";

export const FeatuersList = [
  {
    name: "Real-time integration of HO & Branches",
    describe : 'Manage multiple locations, real-time synchronization of all data ensuring billing & stock transfers.',
    data: [
      {
        imageSrc: Sales,
        title: "Sales",
        description:
          "Any sales in one location is reflected in real time in the corporate module.",
      },
      {
        imageSrc: MultiLocations,
        title: "Multi Locations",
        description:
          "The software provides multi branch / multi location facility.",
      },
    ],
  },
  {
    name: "Estimation & Billing",
    describe : 'Accurate & quick billing with eacy to use Mobile/Tabs Apps',
    data: [
      {
        imageSrc: FastBilling,
        title: "Faster Billing",
        description:
          "Accurate and Quick billing. Enabling quick billing with easy to use Mobile / Tabs Apps.",
      },
      {
        imageSrc: DiscountManagemnet,
        title: "Discount Management",
        description:
          "Template based discounting. Measured Discounting options.",
      },
      {
        imageSrc: MutipleModes,
        title: "Multiple Modes",
        description: "Multiple Modes of Billing Options.",
      },
      {
        imageSrc: SalesApp,
        title: "Sales App",
        description: "Mobile app based estimation.",
      },
      {
        imageSrc: BluetoothPrinter,
        title: "Bluetooth Printer",
        description:
          "Bluetooth Printer Integrated Solution , enabling TABS and Mobile app generate the estimates and bills.",
      },
      {
        imageSrc: Quotations,
        title: "Quotations",
        description:
          "Create error-free quotations with zinger - select customers, add articles. Send link for a quotation by downloading it as a pdf, mail it directly, or get it printed.",
      },
      {
        imageSrc: Barcode,
        title: "Bar code / QR Code",
        description: "Bar code  / QR Code billing and asset enabled tracking.",
      },
    ],
  },
  {
    name: "Stock & RFID Inventory Management",
    describe : 'Track inventory, initiate transfers across stores. Multi-level user authorisation for issuing/purchasing of stock',
    data: [
      {
        imageSrc: ProfessionalPurchaseOrders,
        title: "Professional purchase orders in seconds",
        description:
          "Better to be ready before hitting rock bottom! Create professional purchase orders when stocks run low, avoid human errors and save time on corrections. Add comments to facilitate communications with your suppliers.",
      },
      {
        imageSrc: Expenses,
        title: "Convert purchase orders to expenses",
        description:
          "Make a record of every penny spent, instantly convert purchase orders to expenses or purchases and get a comprehensive data on your expenditures.",
      },
      {
        imageSrc: StatusOverview,
        title: "Status Overview",
        description:
          "Get a status overview of your purchase orders - Get an overview of all your purchase orders sorted according to status and take actions accordingly. The direct search feature will substantially reduce the time and effort invested in document hunting.",
      },
      {
        imageSrc: PurchaseOrders,
        title: "Purchase Order",
        description:
          "Take effective control of your purchase orders. The automated system helps you keep track of your expenses and ensures everything functions smoothly.",
      },
      {
        imageSrc: StockMovement,
        title: "Stock Movement",
        description:
          "Keep a complete track record of your stock with an end-to-end tracking system that helps you track the movement of each item right from the corporate-office / warehouse / branches to the salespoint.",
      },
    ],
  },
  {
    name: "Asset Tracking Management",
    describe : "Manage and track all your assets in a single view from anywhere.",
    data : [
      {
        imageSrc:AssetManagemnet,
        title:"Track Asset Movement",
        description:""
      }
    ] 
  },
  {
    name: "Chit & Schemes Module",
    describe :'Enroll, track & follow-up of the various savings schemes & its payments.',
    data: [
      {
        imageSrc: SavingScheme,
        title: "Saving Schemes",
        description:
          "Helps handle saving schemes of multiple types or users can use a preconfigured setup of schemes.",
      },
      {
        imageSrc: SchemeAnalysis,
        title: "Scheme Analysis",
        description:
          "The schemes are  integrated with pos, billing and financial accounting.",
      },
    ],
  },
  {
    name: "Dealer Management",
    data: [
      {
        imageSrc: DealerManagement,
        title: "Dealer Management",
        description:
        "Manage dealer details, track each metal transactions issue & receipt, and payments.",
      }
    ],
  },
  {
    name: "Order Management",
    data: [
      {
        imageSrc: OrderManagement,
        title: "Order Management",
        description :'Tracking of orders, execution, verify status & payments.',
      }
    ],
  },
  {
    name: "Repair Management",
    data: [
      {
        imageSrc: OrderManagement,
        title: "Repair Management",
        description :'Tracking of repairs, execution, verify status & payments.',
      }
    ],
  },
  {
    name: "Karighar",
    describe :'Provides options for jewelry orders re-evaluation & quality analysis.',
    data: [
      {
        imageSrc: Module,
        title: "Metal Issue & Return",
        description: "",
      },
      {
        imageSrc: StandardizationOfMetal,
        title: "Standardization",
        description: "",
      },
      {
        imageSrc: QualityEvaluation,
        title: "Quality Evaluation",
        description: "",
      },
      {
        imageSrc: MetalToOrnamentConversion,
        title: "Ornament Conversion",
        description: "",
      },
    ],
  },
  {
    name: "Accounting",
    describe :'Purchase & Sale invoices, expense tracking, daily accounting & GSTR/VAT compatible reports.',
    data: [
      {
        imageSrc: ManageTaxableInvoices,
        title: "Manage Taxable Invoices",
        description:
          "Generates bills with GST / VAT based taxes. Tracks invoices and expenses record GST data which can be later exported to manage taxes.",
      },
      {
        imageSrc: ManageDocument,
        title: "Manage Documents",
        description:
          "Maintain documents of all transactions in a paper-free, clutter-free manner with Zinger. The software also allows you to upload images of articles for future reference.",
      },
      {
        imageSrc: Reports,
        title: "GST Compatible Reports",
        description: "GST and VAT Compatible Reports.",
      },
      {
        imageSrc: CreditandDebitDashboard,
        title: "Credit and Debit Dashboard",
        description:
          "Get category-wise and period-wise customer data and the credit offered to them in a few clicks. Also, know the excess payment made to your suppliers and hence get a complete view of your transactions.",
      },
      // {
      //   imageSrc: Expenses,
      //   title: "Expenses",
      //   description:
      //     "Based on your purchases and expenses, the feature allows you to get an overview of expenses for a given month.",
      // },
      {
        imageSrc: Expenditure,
        title: "Expenses",
        description:
          "Get a complete overview of your expenses, identify cost-saving opportunities, and control miscellaneous expenses with Zinger.",
      },
    ],
  },
  {
    name: "Staff (Sales) Management",
    data: [
      {
        imageSrc: StaffManagement,
        title: "Staff (Sales) Management",
        description :'Tracking sales performance & incentives of staff.',
      }
    ],
  },
  {
    name: "Purchase Management",
    data: [
      {
        imageSrc: PurchaseManagement,
        title: "Purchase Management",
        description :'Keep track of the stock requirements of branches. Set re-order levels & set reminders for pending orders.',
      }
    ],
  },
  {
    name: "HR & Payroll Module",
    describe :'Track employee attendance with bio-metric scanners, card scanners, etc. Leave approvals, calculation of salaries & adjustment of advances.',
    data: [
      {
        imageSrc: EmployeeManagement,
        title: "Employee Management",
        description:
          "It captures employee details and maintenance of records in quick and easy manner. It allows you to import employee details, create unlimited designation types and maintain salary structures. It can record each detail right from the joining date, confirmation date, statutory details.",
      },
      {
        imageSrc: Attendance,
        title: "Attendance Management",
        description:
          "Attendance Management allows to effectively monitoring employee activity. It allows you to create attendance groups, monitor attendance status, record attendance as per shift settings and configure shift settings as per your requirements.",
      },
      {
        imageSrc: LeaveManagement,
        title: "Leave Management",
        description:
          "The Leave Management module helps to maintain both manual credit and debit entry of leaves and update current balance of leaves. It can also keep a track of leave encashment and consider excess leaves a loss of pay.",
      },
      {
        imageSrc: PayrollManagement,
        title: "Payroll Management",
        description:
          "With Payroll Management you can process salary for employees, set the variable monthly value component, calculate over time data and record advance payment. It also helps you to record bonus and arrears with the time period of the arrears and the salary component. It can define TDS deduction section wise for employees and create the final settlement list for each employee.",
      },
      {
        imageSrc: Reports,
        title: "Reports",
        description:
          "Leave Register, Attendance Register, Pay slips, Payroll Register, Component wise Salary Register, PF Report, PT Report and ESIC Register.",
      },
    ],
  },
  {
    name: "Non-Sale Stock Management",
    data: [
      {
        imageSrc: NonSaleStockManagement,
        title: "Non-Sale Stock Management",
        description :'Tag non sale gift items, auto update of non sale inventory status.',
      }
    ],
  },
  {
    name: "Customer Relationship Management & it's Analysis",
    describe :'Customer entries, document management, understanding customer choices & their decision making behaviour.',
    data: [
      {
        imageSrc: CustomerOverview,
        title: "Customer Overview",
        description:
          "Have a clear overview of your customers instead of tracking them individually. Get details regarding the number of customers, including all the transactions made.",
      },
      {
        imageSrc: Contact,
        title: "Contact / Customer List",
        description:
          "Maintain the database of all the customers at one place access customer information such as name, address, contact details instantly. You can track sales overview, invoices and quotation sent to an individual customer.",
      },
      {
        imageSrc: PurchasingHistory,
        title: "Purchasing History & Payment History",
        description:
          "Customer Feedback Scores Customer/salesperson history Standard discounts & Special pricing.",
      },
    ],
  },
  {
    name: "Feedback Management & it's Analysis",
    describe :'Capture & track customer feedback with customised questionnaire & scorecard.',
    data: [
      {
        imageSrc: SalesFeedback,
        title: "Customer Satisfaction",
        description:
          "Measure and track customer Satisfaction via feedback surveys and provide the best service.",
      },
      {
        imageSrc: ProductFeedback,
        title: "Product Feedback",
        description: "Track inventory, initiate transfers across stores.",
      },
      {
        imageSrc: FeedbackAnalysis,
        title: "Customized Feedback",
        description: "Customise questionnaire and feedback category.",
      },
    ],
  },
  {
    name: "Enquiry & Follow-up Management",
    describe :'Track, follow & engage the customer enquiries on a timely basis. ',
    data: [
      {
        imageSrc: Enquiries,
        title: "Enquiry / Lead Management",
        description:
          "Zinger helps you to capture all your incoming leads from prospective or existing customers along with their contact details, required products or services, value of the lead etc. Assign leads to sales executive along with the follow up details.",
      },
      {
        imageSrc: FollowupManagement,
        title: "Follow-Up Management",
        description:
          "Zinger makes it easy to enter and track follow-ups or activities required on the leads. Get timely email / sms / whatsapp reminders of your follow-ups. Track today’s follow-ups, upcoming follow-ups and overdue follow-ups from CRM Dashboard. Helps keep record of completed follow-ups along with easy entry of next follow-up from the single screen. Enter lead related notes for your internal users.",
      },
    ],
  },
  {
    name: "Field Sales Management",
    data: [
      {
        imageSrc: FieldSalesManagement,
        title: "Field Sales Management",
        description :'Mobile app enables to track collections, pending payments, etc.,',
      }
    ],
  },
  {
    name: "Location Tracking Module",
    data: [
      {
        imageSrc: LocationTrackingModule,
        title: "Location Tracking Module",
        description :'Track employee travel details.',
      }
    ],
  },
  {
    name: "Scheduled Reports & Notifications",
    describe :'Access real-time reports of business from any web-enabled device & makes data-driven decisions for the efficient business.',
    data: [
      {
        imageSrc: Notification,
        title: "Notifications",
        description:
          "Get email or push notifications for all the account activities such as accepting quotations, overdue payments, stock levels, and much more.",
      },
      {
        imageSrc: AutomatedReminder,
        title: "Automated Reminders",
        description:
          "Our automated system handles the payment reminders for you. Every time the software detects due payments , it will generates an automated reminder to the customer according to the degree of automation configured.",
      },
    ],
  },
  {
    name: "User Entitlement Management",
    describe :'Configure user roles & permissions for staff depending on the role.',
    data: [
      {
        imageSrc: CouponManagement,
        title: "Tailored Access",
        description:
          "Tailored access - The admin reserves the power to grant need-based access to different modules & widgets for the user roles.",
      },
      {
        imageSrc: ProgrammerManagement,
        title: "Tailored Widgets",
        description:
          "The admin reserves the power to grant need-based access to different modules & widgets.",
      },
    ],
  },
  {
    name: "Track Critical Software Activity Logs",
    describe :'',
    data: [
      {
        imageSrc: TrackCriticalSoftwareActivityLogs,
        title: "Track Activity Logs",
        description:
          "Track user activity in critical modules",
      }
    ],
  },
  {
    name: "Loyalty & Reward Programs",
    describe :'Supports loyalty/redeem points program which increase the customer footprints & makes customer delight.',
    data: [
      {
        imageSrc: LoyaityManagement,
        title: "Points & Rewards",
        description:
          "Increase savings and repeat purchases with an exciting rewards program that provides your customers with the benefits they most want.",
      },
      {
        imageSrc: VipProgram,
        title: "VIP Program",
        description:
          "Boost sales and create brand advocates by rewarding customers for referring their friends and buddies.",
      },
      {
        imageSrc: CustomIntegration,
        title: "Referral Program",
        description:
          "Give your most loyal customers more reasons to spend with your brand with exclusive VIP rewards.",
      },
    ],
  },
  {
    name: "Whatsapp / E-mail / SMS",
    data: [
      {
        imageSrc: WhatsappEmailSMS,
        title: "Whatsapp / E-mail / SMS",
        description :'Send greets & promotion messages to customers through E-mail/SMS or WhatsApp messenger. Send regular reports to business owner.',
      }
    ],
  },
  {
    name: "Third Party Software Integration",
    describe :'Ready to use plugins for Integration of Tally, Tanika, Knowlarity & MirrAR.',
    data: [
      {
        imageSrc: TallyIntegration,
        title: "Tally Integration",
        description:
          "Tally Integration ,  Helps avoid duplication of entries in multiple softwares.",
      },
    ],
  },
  {
    name: "Third Party Hardware Integration",
    describe :'RFID handheld device for sales & audit, android tabs, weigh scales & bio-metrics.',
    data: [
      {
        imageSrc: Integration,
        title: "Integration",
        description:
          "Bluetooth Printer Integration - Options to print the quotations and estimates with Bluetooth interated mobile apps.",
      },
      {
        imageSrc: WeighScale,
        title: "Weigh Scale Integration",
        description:
          "Avoid manual errors in entering the weight with this integrated solution.",
      },
      {
        imageSrc: RFID,
        title: "RFID Integrated Solution",
        description:
          "Handreader and Smart tray solutions to provide faster auditing and billing process.",
      },
    ],
  },
  {
    name: "HUID (Hallmarking Unique Identification)",
    data: [
      {
        imageSrc: HUID,
        title: "HUID (Hallmarking Unique Identification)",
        description :'HUID available in product & related reports.',
      }
    ],
  },
  {
    name: "Multi-Lingual & Multi-Currency Facility",
    describe :'Software supports multiple languages & multi-currency.',
    data: [
      {
        imageSrc: Setting,
        title: "Settings",
        description:
          "Multi Lingual & Multi Currency Settings to cater the customer(regional) needs.",
      },
    ],
  },
  {
    name: "VR-Based Solution & Online Video",
    describe :'Virtual live demo of products for customer delight',
    data: [
      {
        imageSrc: VirtualReality,
        title: "Virtual Reality",
        description: "",
      },
      {
        imageSrc: VideoCall,
        title: "Video Call",
        description: "",
      },
    ],
  },
  {
    name: "E-Commerce / E-Brochure Service",
    data: [
      {
        imageSrc: ECommerce,
        title: "E-Commerce / E-Brochure Service",
        description :'Display of products & its details to purchase online by customers.',

      },
    ],
  },
  {
    name:(<span>Marketing 360<sup>o</sup> Solution</span>),
    data: [
      {
        imageSrc: Marketing360oSolution,
        title: (<span>Marketing 360<sup>o</sup> Solution</span>),
        description :'Allows organizations to evaluate customer purchase pattern & drive the marketing team for better performance.',
      }
    ],
  },
  {
    name: "Online Payment Module Integration",
    describe :'Generate payment link for customer, accept payments for purchase plan through mobile apps, multiple payment modes, credit card, debit card, UPI & netbanking integrated with payment gateway (Pay-G, Razor Pay, CC Avenue).',
    data: [
      {
        imageSrc: SocialMediaIntegration,
        title: "UPI Integrated Solution",
        description: "UPI Integated Solution.",
      },
      {
        imageSrc: InstantPayment,
        title: "Instant Payment Solution",
        description: "Link Based payment Solutions.",
      },
      {
        imageSrc: PaymentSolution,
        title: "Payment Solutions",
        description: "Multiple payment solutions.",
      }
    ],
  },
  {
    name: "Online Video Demo Module",
    describe :'Schedule demo as per client requirement & analyze status of the demo.',
    data: [
      {
        imageSrc: Sales,
        title: "Sales",
        description:
          "Increases sales by offering customers an immediate channel to schedule an appointment with the sales team.",
      },
      {
        imageSrc: TimeManagement,
        title: "Time Management",
        description:
          "Reduce wait-time peaks by directing customers to a branch with virtual product demo.",
      },
    ],
  },
  {
    name: "MIS Reports",
    describe :'Get standard/customized reports to track the health of the business; Monitor the sales on-the-go with the Mobile App; track the outlets performance centrally.',
    data: [
      {
        imageSrc: KPI,
        title: "Key Performance Indicators",
        description:
          "Purchase Indicators Sales Target Indicators Expense Budget Indicators Margin Indicators Business Indicators.",
      },
      {
        imageSrc: BusinessIntelligence,
        title: "Business Intelligence",
        description: "Graphical reporting with gauge and graphs & tables.",
      },
      {
        imageSrc: RepairModule,
        title: "Management Information System",
        description:
          "Report on Inventory, Customers, Suppliers, Sales, Financials and Analysis Reports.",
      },
    ],
  },
  {
    name: "Mobile Apps (Android & IOS)",
    describe :'Zinger connects owners, employees & customers on a single mobile platform.',
    data: [
      {
        imageSrc: business360app,
        title: (<span>Business 360<sup>o</sup> App <br></br></span>),
        description:(<ol style={{listStyle:"number",paddingLeft:"20px"}}>
          <li style={{textAlign:"left",marginBottom:"10px",lineHeight:"20px"}}>  Monitor Overall Sales & Dealer Transaction Details </li>
          <li style={{textAlign:"left",lineHeight:"15px"}}>  Stock Details and Employee Sales Performance <br></br> <span style={{fontSize:"12px"}}>(Employee Reports, Product & Audit Details) </span></li>
          <li style={{textAlign:"left",marginTop:"4px"}}>  Book Keeping Details </li>
          <li style={{textAlign:"left"}}>  Notifications & Alerts </li>
          <li style={{textAlign:"right",listStyle:"none"}}> ...and many more.,</li>
          </ol>),
      },
      {
        imageSrc: productcatalogueapp,
        title: (<span>Product Catalogue App <br></br></span>),
        description:(<ol style={{listStyle:"number",paddingLeft:"20px"}}>
          <li style={{textAlign:"left"}}>  Product Details with Images </li>
          <li style={{textAlign:"left"}}>   Easier to Interact and Track Customer Interest </li>
          <li style={{textAlign:"left"}}>  Broadcasting New Arrivals </li>
          <li style={{textAlign:"left"}}> Convert the Prospects to book or Purchase Online</li>
          <li style={{textAlign:"left"}}> Improve Outlets Digital Footprints</li>
          <li style={{textAlign:"right",listStyle:"none"}}> ...and many more.,</li>
          </ol>),
      },
      {
        imageSrc: savingsapp,
        title: (<span>Soft Retail App <br></br></span>),
        description:(<ol style={{listStyle:"number",paddingLeft:"20px"}}>
          <li style={{textAlign:"left"}}>  Faster Billing & Estimation </li>
          <li style={{textAlign:"left"}}>   Accurate Calculation </li>
          <li style={{textAlign:"left"}}>  Track Enquiry & Follow-Ups </li>
          <li style={{textAlign:"left"}}> Track Employee Travel Locations</li>
          <li style={{textAlign:"left"}}>  Improve Sales Efficiency</li>
          <li style={{textAlign:"left"}}>   Demo Management (Online & Offline)</li>
          <li style={{textAlign:"left"}}>  Notifications & Alerts</li>
          <li style={{textAlign:"right",listStyle:"none"}}> ...and many more.,</li>
          </ol>),
      },
      {
        imageSrc: softretail,
        title: (<span>Savings App <br></br></span>),
        description:(<ol style={{listStyle:"number",paddingLeft:"20px"}}>
          <li style={{textAlign:"left"}}>   Daily Metal Rate Details  </li>
          <li style={{textAlign:"left"}}>    Scheme Enrollment </li>
          <li style={{textAlign:"left"}}> Online Payment </li>
          <li style={{textAlign:"left"}}> Payment Reminders & Acknowledgment</li>
          <li style={{textAlign:"left"}}> Notifications & Alerts</li>
          <li style={{textAlign:"right",listStyle:"none"}}> ...and many more.,</li>
          </ol>),
      },
    ],
  },
  {
    name: "Customizable Dashboard",
    data: [
      {
        imageSrc: Dashboard,
        title: "Dashboard",
        description:
          "Your dashboard is the overall view of your business. Get real insights about invoices, stock, payments, and sales at any given point in time.",
      },
    ],
  },
];
